import React, { Fragment } from 'react';
import UserListContainer from '../../containers/users/index-container';
import CreateClientContainer from '../../containers/users/clients/create-container';
import CreateContainer from '../../containers/users/create-container';
import ClientListContainer from '../../containers/users/clients/index-container';
import { Routes,Route, Link } from 'react-router-dom';
import ManagerListContainer from '../../containers/users/managers/index-container';

import { Navigate } from 'react-router-dom';
import ChangeDetailsContainer from '../../containers/users/auth/change-details-container';
import BatchCreateContainer from '../../containers/users/batch-create-container';

function AdminManage(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    // let defaultAdminView = null;

    // if(props.permissions.Users){
    //     defaultAdminView  = <UserListContainer />
    // } else if (props.permissions.Clients){
    //     defaultAdminView  = <ClientListContainer />
    // }

    return (

        <div className="admin-container">
            <div className="admin-head">
                <div className="shard-one"></div>
                <div className="content">
                    <nav className='width-12 admin-nav'>
                        <ul>
                        {props.permissions.Users && 
                            <li>
                                <Link to='/admin/manage/users' >Users</Link>
                            </li>
                        }
                        {props.permissions.Clients && 
                        <Fragment>
                            <li>
                                <Link to='/admin/manage/clients' >Clients</Link>
                            </li>
                        
                            <li>
                                <Link to='/admin/manage/managers' >Managers</Link>
                            </li>
                            </Fragment>
                        }
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="content core-admin">
                <Routes>
                    <Route index element={<UserListContainer />} />
                    <Route path='/users' element={<UserListContainer />} />
                    <Route exact path='/users/upload' element={<BatchCreateContainer />} />
                    <Route path='/users/:userRef' element={<CreateContainer />} />
                    <Route path='/clients' element={<ClientListContainer/>} />
                    <Route path='/users/self' element={<ChangeDetailsContainer/>} />
                    <Route path='/clients/:clientRef' element={<CreateClientContainer/>} />
                    <Route path='/managers' element={<ManagerListContainer/>} />
                </Routes>
            </div>
          
        </div>
    )
}

export default AdminManage