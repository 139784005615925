// Core react and plugins
import React, { Component } from 'react';

// const scoreNames = {
//     0: "Non-existent",
//     1: "Very weak",
//     2: "Weak",
//     3: "Weak",
//     4: "Reasonable",
//     5: "Strong",
//     6: "Very strong"
// }

// Component class itself
class PwMeter extends Component {

    render() {

        return (

            <div className={(this.props.strength.hasEntry && this.props.strength.score < 6) ? "strength-meter display" : "strength-meter"}>
                <p>As a minimum we recommend setting a password that fits these criteria:</p>
                <ul>
                    <li className={(this.props.strength.length) ? "ticked" : ""}>Password should be at least 8 characters long</li>
                    <li className={(this.props.strength.lc) ? "ticked" : ""}>Password should contain at least one lowercase letter</li>
                    <li className={(this.props.strength.uc) ? "ticked" : ""}>Password should be at least one uppercase letter</li>
                    <li className={(this.props.strength.number) ? "ticked" : ""}>Password should be at least one number</li>
                    <li className={(this.props.strength.char) ? "ticked" : ""}>Password should be at least one other character</li>
                    <li className={(this.props.strength.noDict) ? "ticked" : ""}>Password should not contain commonly used passwords like &quot;password&quot;</li>
                </ul>
            </div>

        );

    }
}

export default PwMeter;