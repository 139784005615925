import React, { Fragment } from 'react';

function TextInput(props){

    return (
        <Fragment>
            <div className="input text">
                <label htmlFor={props.id}>{props.label}</label>
                <input type="text" id={props.id} autoComplete={props.id} onChange={props.handleChange } defaultValue={props.value} />
            </div>
        </Fragment>
    )
}

export default TextInput
