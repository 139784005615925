import React from 'react';

function Risk(props){

    let title = 'Risk'
    let para1 = ''
    // let para2 = ''
    // let para3 =''
    let colour = "ylw";

    let band = props.band;

    switch(band){
        case 5:
            title = 'HIGH RISK'
            para1 = 'Your self-assessment results indicate that you are of a high-risk persuasion. You are clearly adventurous by nature and like to get on with something once you have decided it’s for you.'
            // para1 = 'Your self-assessment results indicate that you are of a high-risk persuasion. You are clearly adventurous by nature and like to get on with something once you have decided it’s for you. It is highly likely that you rely on your intuition for general judgement and subject to a brief assessment can nearly always make instant decisions. '
            // para2 = 'It is likely that you have a high activity threshold and can sometimes be seen as impatient. Your high-risk factor suggests that you regularly seek opportunity and like to feel you are making good progress towards the future. You have a more comfortable relationship with the uncertainty of situations than others.'
            // para3 = 'People who have a propensity for higher risk tend to act first and perhaps seek forgiveness later, if required. Quick wins appear to be important, if not absolutely necessary, otherwise, you will move onto the next big thing.'
            colour = "gr";
            break;
        case 4:
            title = 'QUITE HIGH RISK'
            para1 = 'Your self-assessment results indicate that you are of a quite high-risk persuasion. This suggests you are moderately adventurous by nature and like to get tasks done, seeing things through to fruition.'
            // para1 = 'Your self-assessment results indicate that you are of a quite high-risk persuasion. This suggests you are moderately adventurous by nature and like to get tasks done, seeing things through to fruition. It is likely that while you are aware of the relevant data, you tend to trust your intuition for general judgement and after a brief period of reflection you can usually make swift decisions.'
            // para2 = 'It is likely that you have a decent activity threshold and are a keen supporter to others who simply want to get things done. You like to feel you are making steady progress towards the future. You also have an ability to work with a reasonable level of uncertainty.'
            // para3 = 'People who have a propensity for quite higher risk tend to push themselves, though stay within the bounds of reality. They like quick wins and can work well as part of a reasonably paced project.'
            colour = "gr";
            break;
        case 3:
            title = 'BALANCED RISK'
            para1 = 'Your self-assessment results indicate that you are of a balanced risk persuasion. This suggests that you naturally possess a well-balanced outlook and can sometimes be described as the voice of reason.'
            // para1 = 'Your self-assessment results indicate that you are of a balanced risk persuasion. This suggests that you naturally possess a well-balanced outlook and can sometimes be described as the voice of reason. You perhaps work at a decent pace. You rely on both relevant data and personal intuition to corroborate your judgement. Your decision making is usually considered and well founded.'
            // para2 = 'It is likely that you have proportionate levels of activity and reflection which provides a projection of balance to others. You are as comfortable in the present as you are thinking about the future, and you are most likely to deal with certainty and uncertainty in equal measures.'
            // para3 = 'People who have a propensity for balanced risk tend to go with the flow. They are often seen as good team players or connectors. Steady, medium term progress is the order of the day!'
            break;
        case 2:
            title = 'QUITE LOW RISK'
            para1 = 'Your self-assessment results indicate that you are of a quite low-risk persuasion. This suggests that you are mainly risk averse by nature. You are unlikely to be described as adventurous and will probably defer things that carry a reasonable element of risk.'
            // para1 = 'Your self-assessment results indicate that you are of a quite low-risk persuasion. This suggests that you are mainly risk averse by nature. You are unlikely to be described as adventurous and will probably defer things that carry a reasonable element of risk. It is highly likely that you rely on a decent level of data for your general judgement and will prefer a reasonable period of time to make your decisions.'
            // para2 = 'It is likely that you will have a tendency to pause with the presence of higher risk or perhaps defer urgent decisions to others. On the upside this may mean that you could be seen as a reliable person by others. You prefer working in the present and with certainty.'
            // para3 = 'People who have a propensity for quite low risk like to have a framework of general rules and guidelines to follow. Quick wins are mainly seen as lower value aspects, whereas steady progress tends to support a more considered outlook.'
            colour = "gr";
            break;
        case 1:
            title = 'LOW RISK'
            para1 = 'Your self-assessment results indicate that you are of a low-risk persuasion. This suggests that you are naturally risk averse. You could be described as unadventurous and may even defer things that carry any element of risk.'
            // para1 = 'Your self-assessment results indicate that you are of a low-risk persuasion. This suggests that you are naturally risk averse. You could be described as unadventurous and may even defer things that carry any element of risk. It is highly likely that you rely on qualified data for your general judgment and will require large portions of time to assess risky situations. '
            // para2 = 'It is likely that any decisions you make are only in line with the confirmation of low risk. The presence of extreme risk may dictate lengthy consideration or even abdication from decision making at all. On the upside, this can mean you are generally perceived by others as a very reliable person. You probably prefer working in the present and almost definitely with certainty.'
            // para3 = 'People who have a propensity for lower risk tend to follow the rules and seek clear guidelines of how to operate. Quick wins are sometimes seen as frivolous, as doing things in line with their values are far more important than the need for speed.'
            colour = "gr";
            break;
        default: 
            title = 'BALANCED RISK'
            para1 = 'Your self-assessment results indicate that you are of a balanced risk persuasion. This suggests that you naturally possess a well-balanced outlook and can sometimes be described as the voice of reason.'
            // para1 = 'Your self-assessment results indicate that you are of a balanced risk persuasion. This suggests that you naturally possess a well-balanced outlook and can sometimes be described as the voice of reason. You perhaps work at a decent pace. You rely on both relevant data and personal intuition to corroborate your judgement. Your decision making is usually considered and well founded.'
            // para2 = 'It is likely that you have proportionate levels of activity and reflection which provides a projection of balance to others. You are as comfortable in the present as you are thinking about the future, and you are most likely to deal with certainty and uncertainty in equal measures.'
            // para3 = 'People who have a propensity for balanced risk tend to go with the flow. They are often seen as good team players or connectors. Steady, medium term progress is the order of the day!'
            break;
    }

    return (
        <div className={ "narrative risk " + colour }>
            <div className="wording">
                <h5>{title}</h5>
                <p>{para1}</p>
            </div>
        </div>
    )
}

export default Risk