import React from 'react';

function Control(props){

    let title = 'Control'
    let para1 = ''
    // let para2 = ''
    // let para3 =''
    let colour = "ylw";

    let band = props.band;

    switch(band){
        case 5:
            title = 'HIGH CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a high-control persuasion. This would suggest that you enjoy when things are done your way and go according to your plan. You are not afraid to offer your opinion on topics that are of importance or of interest to you. '
            // para2 = 'It may feel frustrating when your advice is not heeded or is ignored and subsequently you may feel it to be a waste of your time to get involved any further. You have a strong clear perspective of what is the right way or wrong way which can be a huge driving force or may close your mind off to alternative methods. '
            // para3 = 'People who have a propensity for high control tend to possess a strong moral compass and firm personal values. Be mindful though, not everyone can live up to these rigid values and when they don’t this may feel like a personal affront. Deadlines and structure are really important to you and will affect the way you operate.'
            colour = "gr";
            break;
        case 4:
            title = 'QUITE HIGH CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a quite high-control persuasion. This would suggest you feel proven when things are done your way and go according to plan, though you don’t always insist upon it.'
            // para2 = 'You like to get involved and are confident in offering an opinion or advice, particularly on topics of interest. If on occasion your input is not adopted, though you can feel frustrated, you have learned that sometimes others are free to make their own decisions. You work well on your own, though you also appreciate being part of a well organised team from time to time.  '
            // para3 = 'People may find it difficult to influence you or change your mind and you will tend to only have a small circle of trusted advisers whose opinion you rely upon, which may incline others to think you are a little stubborn.'
            colour = "gr";
            break;
        case 3:
            title = 'BALANCED CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a balanced control persuasion. This would suggest you are a good listener and tend to have an open mind which is receptive to another person’s point of view.'
            // para1 = 'Your self-assessment results indicate that you are of a balanced control persuasion. This would suggest you are a good listener and tend to have an open mind which is receptive to another person’s point of view. You appreciate harmony though you are unafraid to forego this if something important to you means that you must get your point of view across.'
            // para2 = 'You tend to value and therefore seek counsel in others which helps your critical thinking though on occasions this over absorption of others may delay your decision making. You are willing to take the lead, if required, and equally happy to allow others to lead if you believe they are worthy.'
            // para3 = 'People will appreciate your balanced view and ability to see both sides of the spectrum and often people will come to you for your views. At times though your balanced position may find you torn between siding with one party or the other. Additionally, be mindful that your balanced nature doesn’t render you ineffective.'
            break;
        case 2:
            title = 'QUITE LOW CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a quite low-control persuasion. This would suggest that you are keen to hear what others think and comfortable when other people take control. You are a good team player and have learned that it’s more effective for you to go with the flow.'
            // para2 = 'You do not crave recognition and are most content when left to get on with the job in hand. You appreciate being given direction and being able to contribute your efforts to the greater team. You tend to not be a big agent of change and believe if something is not broken then why try to fix it.'
            // para3 = 'People will appreciate your sense of teamwork and that you are easy to work with as part of the immediate or wider team. You will be supportive of others, tend to not like a drama and maintain a good mood for the benefit of others.'
            colour = "gr";
            break;
        case 1:
            title = 'LOW CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a low-control persuasion. This would suggest that you are happy to follow orders and get the job done. It is important that you have all the tools necessary to complete the work to be done and that the right outcome is achieved.'
            // para1 = 'Your self-assessment results indicate that you are of a low-control persuasion. This would suggest that you are happy to follow orders and get the job done. It is important that you have all the tools necessary to complete the work to be done and that the right outcome is achieved. You tend to keep your opinions to yourself unless asked and are most respectful to other perspectives. You can be very tolerant.'
            // para2 = 'Your respect for others and willingness to listen may be perceived as being easily persuaded. You are willing and able to get involved when invited though you would not intervene naturally of your own accord, and this may give the impression of a lack of pro-activity.'
            // para3 = 'People who have a propensity for low control tend to not like change and much appreciate the status quo. You know what is important to you and less inclined to concern yourself about what others may believe. You appreciate being part of the team and a small part of the bigger picture.'
            colour = "gr";
            break;
        default: 
            title = 'BALANCED CONTROL'
            para1 = 'Your self-assessment results indicate that you are of a balanced control persuasion. This would suggest you are a good listener and tend to have an open mind which is receptive to another person’s point of view.'
            // para1 = 'Your self-assessment results indicate that you are of a balanced control persuasion. This would suggest you are a good listener and tend to have an open mind which is receptive to another person’s point of view. You appreciate harmony though you are unafraid to forego this if something important to you means that you must get your point of view across.'
            // para2 = 'You tend to value and therefore seek counsel in others which helps your critical thinking though on occasions this over absorption of others may delay your decision making. You are willing to take the lead, if required, and equally happy to allow others to lead if you believe they are worthy.'
            // para3 = 'People will appreciate your balanced view and ability to see both sides of the spectrum and often people will come to you for your views. At times though your balanced position may find you torn between siding with one party or the other. Additionally, be mindful that your balanced nature doesn’t render you ineffective.'
            break;
    }

    return (
        <div className={"narrative control " + colour}>
            <div className="wording">
                <h5>{title}</h5>
                <p>{para1}</p>
            </div>
        </div>
    )
}

export default Control