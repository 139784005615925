// TODO: Handling CSRF attacks?!?!
// Check current auth status
// User will have been routed elsewhere if they have already logged in. So there should be no need to do an auth check on this component

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';

// State management
import useFlash from './../../../store/flash';

// View components
import SetPw from '../../../components/users/auth/set-pw';

const SetPwContainer = (props) => {

    // State management
    const [rdr, setRdr] = useState(false);
    const [formData, setFormData] = useState({ password: false, confirm: false });
    const [loading, setLoading] = useState(true);
    const [btnTxt, setBtnTxt] = useState("Set password");
    const [error, setError] = useState(false);
    const [strength, setStrength] = useState({
        score: 0,
        lc: false,
        uc: false,
        length: false,
        number: false,
        char: false,
        noDict: false,
        hasEntry: false
    });
    const [termsRequired, setTermsRequired] = useState(true); // eslint-disable-line
    const [termsAgreed, setTermsAgreed] = useState(false); // eslint-disable-line
    const [share, setShare] = useState(0); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false); // eslint-disable-line
    const urlPath = useParams();

    // Update the auth info local state (based upon entered info)
    const handleUpdate = ( fieldName, e ) => {
        var newFormData = { ...formData };
        newFormData[fieldName] = e.target.value;
        setFormData(newFormData);
        // Update the password strengthometer
        if (fieldName === "password") updateStrength(e.target.value);
    }


    const updateStrength = (pw) => {
        // Set up the object
        var newStrength = { ...strength };
        newStrength.score = 0;

        // Check that we have an entry
        newStrength.hasEntry = (pw.length > 0) ? true : false;
        
        // Is the password long enough
        newStrength.length = (pw.length > 7) ? true : false;
        if (newStrength.length) newStrength.score++;

        // Check for letters and numbers
        var i;
        var character;
        newStrength.number = false;
        newStrength.lc = false;
        newStrength.uc = false;
        for (i = 0; i < pw.length; i++) {

            character = pw.charAt(i);

            // Check for numbers
            if (character !== ' ' && !isNaN(character * 1)) {
                newStrength.number = true;
            } else {

                if (character === character.toUpperCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for uppercase letters
                    newStrength.uc = true;
                } else if (character === character.toLowerCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for lowercase letters
                    newStrength.lc = true;
                }

            }

        }
        if (newStrength.lc) newStrength.score++;
        if (newStrength.uc) newStrength.score++;
        if (newStrength.number) newStrength.score++;

        // Check for special characters
        var pw_test = pw.replace(/ /g, '');
        newStrength.char = false;
        if (/^[a-zA-Z0-9]*$/.test(pw_test) === false && pw.length > 0) {
            newStrength.char = true;
            newStrength.score++;
        }
        
        // Finally a dictionary check
        // var commonPws = JSON.parse('<?php echo $forbiddenWords; ?>');
        var commonPws = ["password"]
        newStrength.noDict = true;
        var lowerPw = pw.toLowerCase();
        for (var j in commonPws) {
            if (lowerPw.indexOf(commonPws[j]) > -1) {
                newStrength.noDict = false;
                break;
            }
        }
        if (newStrength.noDict) newStrength.score++;
        
        setStrength(newStrength);

    }

    // Submit the entered passwords
    const submit = (e) => {

        e.preventDefault();
        setBtnTxt('Setting password...');

        let data = {
            verifyCode:urlPath.resetToken ,
            verifyString:urlPath.resetKey,
            password:formData.password,
            confirmPassword:formData.confirm
        }
        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/reset-password', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                flashActions.set({ msg: "Thanks for setting up a password. You can now sign in.", style: 'grn' });
                setRdr("/");
                return;
            } else if (data.rslt === 'invalid-req'){
                setError({ msg: "Sorry but there was a problem resetting your password. Please enter a secure password", style: 'red' });
                setBtnTxt('Set password');
                return;
            }

            setError({ msg: "Sorry but there was a problem resetting your password. Please check the errors below and try again.", style: "red" });
            

        })
        .catch(function (error) {
            setBtnTxt('Set password');
            setError({ msg: "Sorry but there was a problem resetting your password. Please enter a secure password", style: "red" });
        });

    }

    // On load check whether the link is a valid one...
    const checkTokens = () => {

        var data = {
            "verifyString":urlPath.resetKey,
            "verifyCode":urlPath.resetToken
        }
        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/reset-check', {
            method: 'POST',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false);

            // If there is an error in the keys return to the login screen with an error
            if ( typeof data.rslt == 'undefined' || data.rslt !== 'success' ) {
                flashActions.set({ msg: "Sorry but the link you are using appears to be invalid.", style: 'red' });
                setRdr("/");
                return;
            }

            
        })
        .catch(function (error) {
            flashActions.set({ msg: "Sorry but the link you are using appears to be invalid.", style: 'red' });
            setRdr("/");
        });

    }

    // Handle the terms checkbox
    const toggleTerms = (e) => {
        setTermsAgreed(e.target.checked);
    }

    const toggleShare = (e) => {
        var newShare = e.target.checked ? 1 : 0;
        setShare(newShare);
    }


    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        checkTokens();
    }, []);
    /* eslint-enable */

    return (
        <SetPw
            submitFunc={ submit }
            handleUpdate={ handleUpdate }
            error={ error }
            valErrors={ valErrors }
            rdr={rdr}
            loading={ loading }
            type={ urlPath.setType }
            termsRequired={ termsRequired }
            toggleTerms={ toggleTerms }
            btnTxt={ btnTxt }
            strength={ strength }
            toggleShare={ toggleShare }
        />
    );
}

export default SetPwContainer;
