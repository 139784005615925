export const actions = {
    // Authentication options
    set: ( store, flashInfo ) => {
        store.setState({ 
            msg: flashInfo.msg,
            style: flashInfo.style
        });
    },
    clear: (store) => {
        store.setState({
            msg: false,
            style: false
        });
    }
};