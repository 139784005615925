import React, { Fragment } from 'react';

function Checkbox(props){

    return (
        <Fragment>
            <div className="input checkbox">
                <input type="checkbox" id={props.id} name={props.name} onChange={props.handleChange} value={1} checked={props.checked} />
                <label htmlFor={props.id}>{props.label}</label>
            </div>
        </Fragment>
    )
}

export default Checkbox
