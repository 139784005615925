import React from 'react';

function ResultGraph(props){

    return (
        
        <svg viewBox="0 0  694 650" xmlns="http://www.w3.org/2000/svg" style={{width:'100%',height:'100%'}}>
            <marker id="red-diamond" viewBox="0 0 160 160" refX="80" refY="80" orient="0" markerUnits="userSpaceOnUse" markerWidth="16" markerHeight="16">
                <circle r="50" cx="70" cy="70" stroke="#000000" strokeWidth="0" fill="#000000" />
            </marker>
            <polygon style={{strokeWidth:2}} markerStart="url(#red-diamond)" markerEnd="url(#red-diamond)" markerMid="url(#red-diamond)" fill="none" stroke="black">
                <animate attributeName="points" dur="0.8s" fill="freeze" from={props.dataOriginStr} to={props.dataPositionStr}/*"70,60, 60,130,  30,200,  135,165, 200,200, 210,120, 220,40, 150 20"*/ />
            </polygon>
        </svg>
    )
}

export default ResultGraph