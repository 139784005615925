// Core react and plugins
import React, {  useEffect, useState } from 'react';
import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';
import Section from '../../components/questionnaire/section';
import useFlash from '../../store/flash';
// // State management
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';



const SectionContainer = (props) => {
    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [rdr, setRdr] = useState(false);
    const [authCookie] = useCookies(['jdm']);
    let loginUrl = "/";

    const [flashState, flashActions] = useFlash();// eslint-disable-line
    const [sectionMap,setSectionMap] = useState([])
    const [questions,setQuestions] = useState([])
    const [completedQuestions,setCompletedQuestions] = useState({})//store if section is complete and allow proggresion
    const [error, setError] = useState(false)
    const urlPath = useParams();
    const navigate = useNavigate();
    const [incompleteSections,storeIncompleteSections] = useState({})

    let questionGroupsLoading = false//stop multiple lookups on component mount

    let loadQuestionGroups = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/question-groups', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setSectionMap(data.detail)
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    let loadQuestions = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        if(sectionMap.length === 0){
            return
        }

        let sxnIdSegment = getSlug('section');
        let areaIdSegment = getSlug('area');

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/question-groups/'+sxnIdSegment +'/'+ areaIdSegment, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            let localCompletedQuestions = {}

            console.log(data.detail)
            for(var i = 0; i< data.detail.length;i++){
                if(data.detail[i].pastResponse > 0){
                    localCompletedQuestions[data.detail[i].id] = data.detail[i].pastResponse;
                }
            }
            setQuestions(data.detail)
            setCompletedQuestions(localCompletedQuestions)
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    let submitQuestionnaire = () => {

        setError(false)

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));


        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/submit', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success'){
                setRdr('/my-report')
            } else if (data.rslt === 'unanswered-questions') {
                storeIncompleteSections(data.detail)
                setError({ msg: "Sorry please complete all the questions before submitting.", style: "red question-msg" });
            }else {
                setError({ msg: "Sorry but but there was an error saving the self-assessment.", style: "red question-msg" });
            }
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
                setRdr(loginUrl);
            }
            setError({ msg: "Sorry but but there was an error saving the self-assessment.", style: "red question-msg" });
        });
    }


    const getSlug = (type)=> {

        let slug = -1;

        switch (type){
            case 'section':
                if(typeof urlPath.sectionSlug != 'undefined'){
                    slug = urlPath.sectionSlug;
                } else {
                    slug = sectionMap[0]['sxnId'];
                }
                break;
            default:
                if(typeof urlPath.areaSlug != 'undefined'){
                    slug = urlPath.areaSlug;
                } else {
                    slug = sectionMap[0]['areaId'];
                }
                break;
        }
            
        return slug.toString();
    }

    const getNavigationLinks = (sectionSlug,areaSlug) => {
        let nextLink = '-1'
        let prevLink = '-1'
        let sectionTitle = 'Section'
        let navigationIndex = -1;

        if(sectionMap.length > 0){
            for(let i=0; i <sectionMap.length; i++){
                if(parseInt(sectionMap[i]['sxnId']) === parseInt(sectionSlug)  && parseInt(sectionMap[i]['areaId']) ===  parseInt(areaSlug) ){
                    if(typeof sectionMap[i-1] !== 'undefined'){
                        prevLink = sectionMap[i-1]['sxnId'] +'/'+sectionMap[i-1]['areaId']
                    }
                    if(typeof sectionMap[i+1] !== 'undefined'){
                        nextLink = sectionMap[i+1]['sxnId'] +'/'+sectionMap[i+1]['areaId']
                    } else {
                        nextLink = '/my-report'
                    }
                    sectionTitle = sectionMap[i]['sxnTitle']
                    navigationIndex = i;
                }
            }
        }

        return {nextLink,prevLink,sectionTitle,navigationIndex}
    }

    const  markQuestionAnswered = (questionId,value)=> {
        let newCompletedQuestions = {...completedQuestions}
        newCompletedQuestions[questionId] = value;
        setCompletedQuestions(newCompletedQuestions);
       }
    
    const handleNavigate = (e,link) => {
        e.preventDefault();
        setError(false)
        let questionsAnsweredCount = Object.keys(completedQuestions).length;
        if(questionsAnsweredCount === questions.length){
            navigate(link, {replace: true});
            loadQuestionGroups();
        } else {
            setError({'msg':'Please answer all questions before progressing', style: "red question-msg" })
            return
        }
    }
   
    /*eslint-disable */
    useEffect(() => {
        document.title = 'Self Assessment :: Judgement and Decision Making :: NeuroSphere';
        if(!questionGroupsLoading){
            questionGroupsLoading = true;
            loadQuestionGroups();
        }  
    },[])
    /*eslint-enable */

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [error,urlPath]);

    /*eslint-disable */
   useEffect(() => {
        loadQuestions()
   },[sectionMap,urlPath])
   /*eslint-enable */

   if(sectionMap.length === 0){
        return null;
   }

   //load navigation data
   const areaSlug = getSlug('area')
   const sectionSlug = getSlug('section')
   const {sectionTitle,nextLink,prevLink,navigationIndex} = getNavigationLinks(sectionSlug,areaSlug)

  

    return (
        <Section
            error={error}
            sectionMap={sectionMap}
            questions={questions}
            prevLink={prevLink}
            nextLink={nextLink}
            sectionTitle={sectionTitle}
            areaSlug={ areaSlug }
            sxnSlug={ sectionSlug }
            markQuestionAnswered={markQuestionAnswered}
            submitQuestionnaire={submitQuestionnaire}
            incompleteSections={incompleteSections}
            handleNavigate={handleNavigate}
            setRdr={setRdr}
            rdr={rdr}
            navigationIndex={navigationIndex}
        />
    );
}

export default SectionContainer;