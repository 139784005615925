import React from 'react';
import jsPDF from 'jspdf'
import { Navigate } from 'react-router-dom';
import moment from "moment";

import './../../assets/fonts/Gilroy-Regular-normal';
import './../../assets/fonts/Gilroy-Bold-normal';
import './../../assets/fonts/Gilroy-Medium-normal';

class Download extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    };

    generatePDF = () => {

        let headerFontSize = 15;
        let subheaderFontSize = 12;
        let color = '#000000';
        let brickColor = '#509785';
        let subheadColor = '#558E7F';
        let bodyFontSize = 8.5;

        if(!this.props.checkForAuthCookie()){
            return this.props.setRdr('/')
        }

        var doc = new jsPDF({ 
            orientation: "p",
            format: "a4",
            unit: "mm",
            putOnlyUsedFonts: true
        });

        // Page 1
        // Background images 
        doc.addImage("/pdf-img/head-bg.png", "PNG", 0, 0, 172, 45);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        // Page content
        doc.addImage("/pdf-img/neurosphere-logo.png", "PNG", 22, 34, 72, 15);

        // Title 
        doc.setFont('Gilroy-Bold', 'normal');
        // doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(24)
        var title = (this.props.isCurrentUser) ? "NeuroSphere Report" : "NeuroSphere Report";
        var name = "";
        if (typeof this.props.user.lastName != 'undefined') {
            name = this.props.user.firstName + " " + this.props.user.lastName;
        }
        let date = moment(this.props.reportDetail.submissionDate).format("DD/MM/YYYY");
        doc.text(22, 130, name)
        doc.text(22, 142, title)

        doc.setFontSize(14)
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(22, 155, date)


        // Page 2
        // Background images 
        doc.insertPage()
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Header 
        doc.setFontSize(9)
        doc.text(12, 18, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 25, "Better Judgement and Decision Making");

        // Content
        doc.text(12, 48, "Our Research");
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(12, 57, "During our research into this fascinating subject, we found that our personal character, coupled with our organisational culture was circa 80% responsible for driving our individual reasoning and actions within the workplace.", { maxWidth: 95, lineHeightFactor: 1.6 });
        doc.text(12, 79, "Therefore, consciously being aware of certain character traits helps us to form sound judgement that leads to better decision making at speed. This is fundamentally critical to our individual and collective success.", { maxWidth: 95, lineHeightFactor: 1.6 });

        doc.addImage("/pdf-img/cadm.png", "PNG", 122, 42, 72, 58);

        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        title = (this.props.isCurrentUser) ? "Your NeuroSphere Model" : "NeuroSphere Model";
        doc.text(12, 132, title);

        // The chart 
        // Logo + chart background image
        doc.addImage("/pdf-img/chart-bg.png", "PNG", 45, 148, 120, 120);

        // Assemble the data for the graph
        let risk = this.getData(1, this.props.reportData, 120, 105.3, 208.5);
        let control = this.getData(2, this.props.reportData, 120, 105.3, 208.5);
        let intuition = this.getData(3, this.props.reportData, 120, 105.3, 208.5);
        let adventure = this.getData(4, this.props.reportData, 120, 105.3, 208.5);

        //  Draw the lines to join the points on the graph
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.25)
        doc.line(risk.high.x, risk.high.y, adventure.high.x, adventure.high.y, "S")
        doc.line(control.low.x, control.low.y, adventure.high.x, adventure.high.y, "S")
        doc.line(control.low.x, control.low.y, intuition.low.x, intuition.low.y, "S")
        doc.line(risk.low.x, risk.low.y, intuition.low.x, intuition.low.y, "S")
        doc.line(risk.low.x, risk.low.y, adventure.low.x, adventure.low.y, "S")
        doc.line(control.high.x, control.high.y, adventure.low.x, adventure.low.y, "S")
        doc.line(control.high.x, control.high.y, intuition.high.x, intuition.high.y, "S")
        doc.line(risk.high.x, risk.high.y, intuition.high.x, intuition.high.y, "S")

        // Draw the risk spots on the diagram
        doc.setFillColor(1, 1, 1);

        // Draw the risk spots on the diagram
        doc.circle(risk.high.x, risk.high.y, 1, "F")
        doc.circle(risk.low.x, risk.low.y, 1, "F")

        // Draw the control spots on the diagram
        doc.circle(control.high.x, control.high.y, 1, "F")
        doc.circle(control.low.x, control.low.y, 1, "F")

        // // Draw the data / intuition spots on the diagram
        doc.circle(intuition.high.x, intuition.high.y, 1, "F")
        doc.circle(intuition.low.x, intuition.low.y, 1, "F")

        // // Draw the adventure spots on the diagram
        doc.circle(adventure.high.x, adventure.high.y, 1, "F")
        doc.circle(adventure.low.x, adventure.low.y, 1, "F")


        // Page 3
        // Background images 
        doc.insertPage()
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Header 
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(9)
        doc.text(12, 18, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 25, "Better Judgement and Decision Making");

        // Content
        doc.setFontSize(subheaderFontSize)
        doc.setTextColor(subheadColor)
        doc.text(12, 36, "ABSTRACT’s Definition of Judgement:-");

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setTextColor(color)
        doc.setFontSize(bodyFontSize)
        doc.text(12, 43, "The process of forming an opinion or evaluation by comparing facts and probabilities towards a conclusion or desired outcome.", { maxWidth: 150, lineHeightFactor: 1.6 });

        doc.setFontSize(headerFontSize);
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 76, "Critical Judgement Factors");

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(12, 85, "Risk");

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setTextColor(color)
        doc.setFontSize(bodyFontSize)
        doc.text(12, 96, "The first thing we should consider about risk is that it plays a huge part in forming our judgement and that our perception of risk is largely subjective. Each of us will make a personal assessment of risk in every situation depending upon several crital factors.", { maxWidth: 82, lineHeightFactor: 1.6 });

        // Brick diagram
        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(bodyFontSize - 1)
        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 72, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 80, "PREJUDICES", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 72, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 78, "AVAILABLE INFORMATION", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 88, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 96, "TIME AVAILABILITY", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 88, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 94, "INTEREST WITH THE SUBJECT MATTER", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 104, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 110, "SIMPLICITY OR COMPLEXITY", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 104, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 112, "PERSONALLY INVESTED", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 120, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 127.5, "RISK TOLERANCE", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 120, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 127.5, "GUT INSTINCT", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        // Narrative
        doc.setFillColor('#C5DFD7')
        let rectHeight = (risk.band === 1 || risk.band === 2) ? 88 : 80;
        doc.rect(12, 155, 1.5, rectHeight, "F")

        doc.setFontSize(headerFontSize);
        doc.setTextColor(color)
        doc.setFont('Gilroy-Bold', 'normal');
        title = (this.props.isCurrentUser) ? "Your Risk Results" : "Risk Results";
        doc.text(22, 165, title);

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(22, 173, risk.title);

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color);
        doc.text(22, 180, risk.content, { maxWidth: 155, lineHeightFactor: 1.6 });

        // Page 4
        // Background images 
        doc.insertPage()
        doc.setFont('Gilroy-Regular', 'normal');
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Header 
        doc.setFontSize(9)
        doc.text(12, 18, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 25, "The Spectrum of Consequence");

        // Content 
        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(12, 50, "Risk versus Control");

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color); 
        doc.text(12, 58, "If we take a few minutes to accurately assess the perceived level of risk apportioned to each situation and identify if it is higher or lower risk on the spectrum of consequence matrix, we can then apply the perceived equal amount of higher or lower control to our solution.This should leave us operating within either regulation or within choice.", { maxWidth: 65, lineHeightFactor: 1.6 });

        doc.addImage("/pdf-img/soc.png", "PNG", 98, 35, 104, 95);

        // Narrative
        doc.setFillColor('#C5DFD7')
        rectHeight = (control.band === 1 || control.band === 3) ? 85 : 80;
        if (control.band === 4) rectHeight = 76;
        doc.rect(12, 155, 1.5, rectHeight, "F")

        doc.setFontSize(headerFontSize);
        doc.setTextColor(color)
        doc.setFont('Gilroy-Bold', 'normal');
        title = (this.props.isCurrentUser) ? "Your Control Results" : "Control Results";
        doc.text(22, 165, title);

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(22, 173, control.title);

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color);
        doc.text(22, 180, control.content, { maxWidth: 155, lineHeightFactor: 1.6 });

        // Page 5
        // Background images 
        doc.insertPage()
        doc.setFont('Gilroy-Regular', 'normal');
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Header 
        doc.setFontSize(9)
        doc.text(12, 18, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 25, "Thinking Fast and Slow - Daniel Kahneman");

        // System1 and System2 boxes
        doc.setFillColor('#EAF4F1');
        doc.rect(12, 36, 90, 57, "F");

        doc.setFontSize(12);
        doc.setTextColor('#4B9B85')
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(18, 45, "SYSTEM1", { charSpace: 0.1 });

        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color)
        doc.text(18, 54, [
            "You react to the threat before you recognise it.\n",
            "The points within your NeuroSphere model that are within System1 are areas that drive your natural go - to reactions when forming judgement and making decisions… These are usually 95 % dominant."
        ], { maxWidth: 78, lineHeightFactor: 1.6 });

        doc.setFillColor('#FDEDEF');
        doc.rect(108, 36, 90, 57, "F", { align: "left" });

        doc.setFontSize(12);
        doc.setTextColor('#E46573')
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(114, 45, "SYSTEM2", { charSpace: 0.1 });

        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color)
        doc.text(114, 54, [
            "You recognise the threat and take the best course of action.\n",
            "The points within your NeuroSphere model that are within System2 are areas that are least likely to be used in response to forming judgement and making decisions… These are the ones for development."
        ], { maxWidth: 78, lineHeightFactor: 1.6 });

        // Narrative
        doc.setFillColor('#C5DFD7')
        rectHeight = (intuition.band === 1 || intuition.band === 2) ? 76 : 80;
        doc.rect(12, 102, 1.5, rectHeight, "F")

        doc.setFontSize(headerFontSize);
        doc.setTextColor(color)
        doc.setFont('Gilroy-Bold', 'normal');
        title = (this.props.isCurrentUser) ? "Your Data / Inuition Results" : "Data / Intuition Results";
        doc.text(22, 112, title);

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(22, 120, intuition.title);

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color);
        doc.text(22, 127, intuition.content, { maxWidth: 155, lineHeightFactor: 1.6 });

        // Decision making
        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 195, "ABSTRACT’s Definition of Decision Making");

        doc.setFontSize(bodyFontSize);
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setTextColor(color)
        doc.text(12, 203, "The cognitive process of deciding, resulting in the selection of a belief or course of action chosen from shortlisted alternative options.", { maxWidth: 150, lineHeightFactor: 1.6 });

        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.setTextColor(color);
        doc.text(12, 220, "Critical Decision Making factors");

        doc.setFontSize(bodyFontSize);
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setTextColor(color)
        doc.text(12, 228, "How we choose to process information determines the decisions we make.Even the decision not to make a decision, is a decision. Each of us will regularly make decisions depending upon several critical factors.", { maxWidth: 78, lineHeightFactor: 1.6 });

        // Brick diagram
        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(bodyFontSize - 1)
        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 215, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 223, "TIME PRESSURES", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 215, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 221, "DECISION MAKERS VALUES", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 231, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 237.5, "ORGANISATIONAL POLICY", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 231, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 239, "RATIONALITY", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 247, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 255, "IMPACT SEVERITY", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 247, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 255, "COMPETING PRIORITIES", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(106, 263, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(126, 269, "SOLUTION COMPATIBILTITY", { maxWidth: 30, align: "center" });

        doc.setFillColor('#EAF4F1')
        doc.roundedRect(148, 263, 39, 13.5, 2, 2, "F")
        doc.setTextColor(brickColor)
        doc.text(168, 269, "FREEDOM OF EXPRESSION", { maxWidth: 30, align: "center", lineHeightFactor: 1.3 });
        

        // Page 6
        // Background images 
        doc.insertPage()
        doc.setTextColor(color);
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Header 
        doc.setFontSize(9)
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(12, 18, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 25, "What’s The Question?");

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 35, "To make a decision we must first understand the question.");

        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color);
        doc.text(12, 44, "Einstein was once asked, if he only had one hour to solve a problem, what would he do?");

        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(12, 49, "”If he had only one hour to solve a problem what would he do?” He said…");

        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(10.5);
        doc.text(105, 66, "I would spend 55 minutes trying to understand the question, then I wouldn’t need any more than 5 minutes to find the solution. The solution lies in in forming and developing the precise understanding of the question to create sound.", { maxWidth: 95, align: 'center' });
        doc.setFontSize(9);
        doc.text(105, 92, "Albert Einstein", { maxWidth: 95, align: 'center' });

        doc.addImage("/pdf-img/quote-open.png", "PNG", 50, 62, 6, 5);
        doc.addImage("/pdf-img/quote-close.png", "PNG", 154, 79, 6, 5);

        // Systems section
        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 110, "What’s The Question?");

        doc.setFontSize(bodyFontSize);
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(12, 118, "The question should always be formed around the problem to solve and be a closed action question beginning with ‘Do’ and be self - imposed, complete with ‘where and when’ scenarios.", { maxWidth: 150, lineHeightFactor: 1.6 });

        doc.setFillColor('#EAF4F1');
        doc.rect(12, 130, 90, 28, "F");

        doc.setFontSize(12);
        doc.setTextColor('#4B9B85')
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(18, 139, "SYSTEM1", { charSpace: 0.1 });

        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color)
        doc.text(18, 146, "Usually takes care of ”Yes” or ”No”.", { maxWidth: 78, lineHeightFactor: 1.6 });

        doc.setFillColor('#FDEDEF');
        doc.rect(108, 130, 90, 28, "F", { align: "left" });

        doc.setFontSize(12);
        doc.setTextColor('#E46573')
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(114, 139, "SYSTEM2", { charSpace: 0.1 });

        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color)
        doc.text(114, 146, "Will initially be needed to create the ”Best Alternative” answer.", { maxWidth: 55, lineHeightFactor: 1.6 });

        // Trilogy of answers sxn
        doc.setFillColor('#FFFAEE');
        doc.rect(12, 164, 186, 110, "F", { align: "left" });

        doc.setFontSize(headerFontSize)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(18, 173, "Trilogy of Answers");

        doc.addImage("/pdf-img/trilogy.png", "PNG", 20, 188, 65, 70);

        doc.setFontSize(bodyFontSize + 1)
        doc.text(114, 180, "Problem to solve:");
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(114, 187, "You have heard rumours that career progression in your firm will only be considered for those who regularly attend the office.", { maxWidth: 70, lineHeightFactor: 1.6 });

        doc.setFont('Gilroy-Bold', 'normal');
        doc.setFontSize(bodyFontSize + 1)
        doc.text(114, 207, "Question to answer:");
        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(114, 214, "Do I return to my pre-covid five days per week in the office to be considered for promotion?", { maxWidth: 70, lineHeightFactor: 1.6 });

        doc.setFont('Gilroy-Bold', 'normal');
        doc.setFontSize(bodyFontSize + 1)
        doc.text(114, 229, "Trilogy of answers:");

        doc.setFillColor('#ffffff')
        doc.setDrawColor('#ccc')
        doc.circle(116, 235, 1.5, "DF")

        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(120, 236, "Yes", { maxWidth: 70, lineHeightFactor: 1.6 });

        doc.setFillColor('#ffffff')
        doc.setDrawColor('#ccc')
        doc.circle(116, 242, 1.5, "DF")

        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(120, 243, "No", { maxWidth: 70, lineHeightFactor: 1.6 });

        doc.setFillColor('#ffffff')
        doc.setDrawColor('#ccc')
        doc.circle(116, 249, 1.5, "DF")

        doc.setFont('Gilroy-Medium', 'normal');
        doc.setFontSize(bodyFontSize)
        doc.text(120, 250, "”Best Alternative”: Manage my current hybrid days in the office more considerately to include better organised collaboration and networking opportunities.", { maxWidth: 66, lineHeightFactor: 1.6 });


        // Page 7
        // Background images 
        doc.insertPage()
        doc.addImage("/pdf-img/head-bg-sml.png", "PNG", 0, 0, 84, 22);
        // doc.addImage("/pdf-img/cta-bg.png", "PNG", 0, 199, 210, 106);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        doc.addImage("/pdf-img/logo-sml.png", "PNG", 166, 282, 32, 7);

        // Narrative
        doc.setFillColor('#C5DFD7')
        rectHeight = (adventure.band === 1 || adventure.band === 5 || adventure.band === 4) ? 87 : 82;
        doc.rect(12, 14, 1.5, rectHeight, "F")

        doc.setFontSize(9)
        doc.setFont('Gilroy-Regular', 'normal');
        doc.text(22, 21, "NeuroSphere Report: " + name + ", " + date);
        doc.setFontSize(headerFontSize);
        doc.setTextColor(color)
        doc.setFont('Gilroy-Bold', 'normal');
        title = (this.props.isCurrentUser) ? "Your Adventurous / Cautious Results" : "Adventurous / Cautious Results";
        doc.text(22, 28, title);

        doc.setFontSize(subheaderFontSize);
        doc.setTextColor(subheadColor);
        doc.text(22, 39, adventure.title);

        doc.setFont('Gilroy-Regular', 'normal');
        doc.setFontSize(bodyFontSize);
        doc.setTextColor(color);
        doc.text(22, 47, adventure.content, { maxWidth: 155, lineHeightFactor: 1.6 });

        doc.setFontSize(headerFontSize);
        doc.setTextColor(color)
        doc.setFont('Gilroy-Bold', 'normal');
        doc.text(12, 115, "The Process");

        // CTA
        doc.addImage("/pdf-img/process.png", "PNG", 43, 113, 120, 90);

        // doc.setFontSize(subheaderFontSize);
        // doc.setTextColor(color)
        // doc.setFont('Gilroy-Medium', 'normal');
        // doc.text(105, 240, "Do I click now to activate my personal coaching session?", { maxWidth: 150, align: 'center' });

        // doc.setFontSize(bodyFontSize + 1);
        // doc.text(67, 250, "Yes");
        // doc.text(94, 250, "No");
        // doc.text(120, 250, "Best alternative");

        // doc.setFillColor('#ffffff')
        // doc.setDrawColor('#ccc')
        // doc.circle(62, 249, 1.5, "DF")
        // doc.circle(89, 249, 1.5, "DF")
        // doc.circle(115, 249, 1.5, "DF")

        // doc.setFontSize(subheaderFontSize - 1);
        // doc.setTextColor('#0F8D6C')
        // doc.setFont('Gilroy-Bold', 'normal');
        // doc.text(105, 262, "Click to activate your personal coaching session…", { maxWidth: 150, align: 'center' });

        // doc.setFillColor('#43C9A6');
        // doc.rect(82, 270, 45, 12, "F")

        // doc.setFontSize(11);
        // doc.setTextColor('#ffffff')
        // doc.setFont('Gilroy-Medium', 'normal');
        // doc.text(105, 277, "ACTIVATE NOW", { maxWidth: 45, align: 'center' });

        // doc.link(82, 270, 45, 12, { url: "mailto:info@abstractuk.co.uk "});

        // Back cover
        doc.insertPage()
        // Background images
        doc.addImage("/pdf-img/head-bg.png", "PNG", 0, 0, 172, 45);
        doc.addImage("/pdf-img/foot-bg.png", "PNG", 20, 262, 190, 35);
        // Page content
        doc.addImage("/pdf-img/neurosphere-logo.png", "PNG", 68, 135, 84, 18);

        // Title 
        doc.setFont('Gilroy-Medium', 'normal');
        doc.setTextColor('#000000')
        doc.setFontSize(11.2)
        doc.text(72, 165, "www.neurosphereprogramme.com")

        doc.save('neurosphere-report.pdf')
    }

    getData = (areaId, reportData, wh, xCentre, yCentre) => {
        
        var band = false;
        for (var i in reportData) {
            if (reportData[i].areaId === areaId) {
                band = reportData[i].band;
            }
        }

        if (!band) {
            return { title: "", content: "" }
        }

        let data = {
            1: {
                1: {
                    "title": "Low Risk",
                    "content": [
                        "Your self-assessment results indicate that you are of a low-risk persuasion. This suggests that you are naturally risk averse. You could be described as unadventurous and may even defer things that carry any element of risk. It is highly likely that you rely on qualified data for your general judgment and will require large portions of time to assess risky situations.\n",
                        "It is likely that any decisions you make are only in line with the confirmation of low risk. The presence of extreme risk may dictate lengthy consideration or even abdication from decision making at all. On the upside, this can mean you are generally perceived by others as a very reliable person. You probably prefer working in the present and almost definitely with certainty.\n",
                        "People who have a propensity for lower risk tend to follow the rules and seek clear guidelines of how to operate. Quick wins are sometimes seen as frivolous, as doing things in line with their values are far more important than the need for speed."
                    ], 
                    "band": 1
                },
                2: {
                    "title": "Quite Low Risk",
                    "content": [
                        'Your self-assessment results indicate that you are of a quite low-risk persuasion. This suggests that you are mainly risk averse by nature. You are unlikely to be described as adventurous and will probably defer things that carry a reasonable element of risk. It is highly likely that you rely on a decent level of data for your general judgement and will prefer a reasonable period of time to make your decisions.\n',
                        'It is likely that you will have a tendency to pause with the presence of higher risk or perhaps defer urgent decisions to others. On the upside this may mean that you could be seen as a reliable person by others. You prefer working in the present and with certainty.\n',
                        'People who have a propensity for quite low risk like to have a framework of general rules and guidelines to follow. Quick wins are mainly seen as lower value aspects, whereas steady progress tends to support a more considered outlook.'
                    ],
                    "band": 2
                },
                3: {
                    "title": "Balanced Risk",
                    "content": [
                        'Your self-assessment results indicate that you are of a balanced risk persuasion. This suggests that you naturally possess a well-balanced outlook and can sometimes be described as the voice of reason. You perhaps work at a decent pace. You rely on both relevant data and personal intuition to corroborate your judgement. Your decision making is usually considered and well founded.\n',
                        'It is likely that you have proportionate levels of activity and reflection which provides a projection of balance to others. You are as comfortable in the present as you are thinking about the future, and you are most likely to deal with certainty and uncertainty in equal measures.\n',
                        'People who have a propensity for balanced risk tend to go with the flow. They are often seen as good team players or connectors. Steady, medium term progress is the order of the day!'
                    ],
                    "band": 3
                },
                4: {
                    "title": "Quite High Risk",
                    "content": [
                        'Your self-assessment results indicate that you are of a quite high-risk persuasion. This suggests you are moderately adventurous by nature and like to get tasks done, seeing things through to fruition. It is likely that while you are aware of the relevant data, you tend to trust your intuition for general judgement and after a brief period of reflection you can usually make swift decisions.\n',
                        'It is likely that you have a decent activity threshold and are a keen supporter to others who simply want to get things done. You like to feel you are making steady progress towards the future. You also have an ability to work with a reasonable level of uncertainty.\n',
                        'People who have a propensity for quite higher risk tend to push themselves, though stay within the bounds of reality. They like quick wins and can work well as part of a reasonably paced project.'
                    ],
                    "band": 4
                },
                5: {
                    "title": "High Risk",
                    "content": [
                        'Your self-assessment results indicate that you are of a high-risk persuasion. You are clearly adventurous by nature and like to get on with something once you have decided it’s for you. It is highly likely that you rely on your intuition for general judgement and subject to a brief assessment can nearly always make instant decisions.\n',
                        'It is likely that you have a high activity threshold and can sometimes be seen as impatient. Your high-risk factor suggests that you regularly seek opportunity and like to feel you are making good progress towards the future. You have a more comfortable relationship with the uncertainty of situations than others.\n',
                        'People who have a propensity for higher risk tend to act first and perhaps seek forgiveness later, if required. Quick wins appear to be important, if not absolutely necessary, otherwise, you will move onto the next big thing.'
                    ],
                    "band": 5
                }
            },
            2: {          
                1: {
                    "title": "LOW CONTROL",
                    "content": [
                        'Your self-assessment results indicate that you are of a low-control persuasion. This would suggest that you are happy to follow orders and get the job done. It is important that you have all the tools necessary to complete the work to be done and that the right outcome is achieved. You tend to keep your opinions to yourself unless asked and are most respectful to other perspectives. You can be very tolerant.\n',
                        'Your respect for others and willingness to listen may be perceived as being easily persuaded. You are willing and able to get involved when invited though you would not intervene naturally of your own accord, and this may give the impression of a lack of pro-activity.\n',
                        'People who have a propensity for low control tend to not like change and much appreciate the status quo. You know what is important to you and less inclined to concern yourself about what others may believe. You appreciate being part of the team and a small part of the bigger picture.'
                    ],
                    "band": 1
                },
                2: {
                    "title": "QUITE LOW CONTROL",
                    "content": [
                        'Your self-assessment results indicate that you are of a quite low-control persuasion. This would suggest that you are keen to hear what others think and comfortable when other people take control. You are a good team player and have learned that it’s more effective for you to go with the flow.\n',
                        'You do not crave recognition and are most content when left to get on with the job in hand. You appreciate being given direction and being able to contribute your efforts to the greater team. You tend to not be a big agent of change and believe if something is not broken then why try to fix it.\n',
                        'People will appreciate your sense of teamwork and that you are easy to work with as part of the immediate or wider team. You will be supportive of others, tend to not like a drama and maintain a good mood for the benefit of others.'
                    ],
                    "band": 2
                },
                3: {
                    "title": "BALANCED CONTROL",
                    "content": [
                        'Your self-assessment results indicate that you are of a balanced control persuasion. This would suggest you are a good listener and tend to have an open mind which is receptive to another person’s point of view. You appreciate harmony though you are unafraid to forego this if something important to you means that you must get your point of view across.\n',
                        'You tend to value and therefore seek counsel in others which helps your critical thinking though on occasions this over absorption of others may delay your decision making. You are willing to take the lead, if required, and equally happy to allow others to lead if you believe they are worthy.\n',
                        'People will appreciate your balanced view and ability to see both sides of the spectrum and often people will come to you for your views. At times though your balanced position may find you torn between siding with one party or the other. Additionally, be mindful that your balanced nature doesn’t render you ineffective.'
                    ],
                    "band": 3
                },
                4: {
                    "title": "QUITE HIGH CONTROL",
                    "content": [
                        'Your self-assessment results indicate that you are of a quite high-control persuasion. This would suggest you feel proven when things are done your way and go according to plan, though you don’t always insist upon it.\n',
                        'You like to get involved and are confident in offering an opinion or advice, particularly on topics of interest. If on occasion your input is not adopted, though you can feel frustrated, you have learned that sometimes others are free to make their own decisions. You work well on your own, though you also appreciate being part of a well organised team from time to time.\n',
                        'People may find it difficult to influence you or change your mind and you will tend to only have a small circle of trusted advisers whose opinion you rely upon, which may incline others to think you are a little stubborn.'
                    ],
                    "band": 4
                },
                5: {
                    "title": "HIGH CONTROL",
                    "content": [
                        'Your self-assessment results indicate that you are of a high-control persuasion. This would suggest that you enjoy when things are done your way and go according to your plan. You are not afraid to offer your opinion on topics that are of importance or of interest to you.\n',
                        'It may feel frustrating when your advice is not heeded or is ignored and subsequently you may feel it to be a waste of your time to get involved any further. You have a strong clear perspective of what is the right way or wrong way which can be a huge driving force or may close your mind off to alternative methods.\n',
                        'People who have a propensity for high control tend to possess a strong moral compass and firm personal values. Be mindful though, not everyone can live up to these rigid values and when they don’t this may feel like a personal affront. Deadlines and structure are really important to you and will affect the way you operate.'
                    ],
                    "band": 5
                }
            },
            3: {    
                1: {
                    "title": "HIGH INTUITION",
                    "content": [
                        'Your self-assessment results indicate that you are of a high intuition persuasion. You are clearly highly sensitive to what others may be feeling and very receptive to other people’s energies. It is highly likely that you rely on your intuition for general judgement and can nearly always make instant decisions regardless of the situation.\n',
                        'It is likely that you act more on instinctive feeling rather than conscious reasoning, appreciating instinctively when something feels right or wrong. Your high intuition factor suggests your responses are reached with little apparent effort, and typically without conscious awareness.\n',
                        'People who have a propensity for high intuition tend to form judgement effortlessly and can make decisions with speed. They usually rely on the outcomes of their previous decisions to form the next series of intuitive decisions.'
                    ],
                    "band": 1
                },
                2: {
                    "title": "QUITE HIGH INTUITION",
                    "content": [
                        'Your self-assessment results indicate that you are of a quite high intuition persuasion. This suggests you are sensitive to what others may be feeling and receptive to others’ emotions. Although you will more naturally rely on your intuition for general judgement you will also appreciate some proof as confirmation for your decision making.\n',
                        'It is likely that while you may act mainly on instinctive feeling you will also look for some supporting evidence to help apply a modicum of conscious reasoning, ensuring reasonable levels of accuracy are attained.\n',
                        'People who have a propensity for quite high intuition recognise their gut feel, experience and recognition to form judgement and make decisions. They work particularly well with common practices or within familiar situations. '
                    ],
                    "band": 2
                },
                3: {
                    "title": "BALANCED DATA AND INTUITION",
                    "content": [
                        'Your self-assessment results suggest that you are happy striking a balance of gathering just enough data and then checking in with what your intuition is telling you. Having a combination of factual evidence and gut feel provides you with the confidence and confirmation you need to form sound judgement.\n',
                        'It is likely you can make reasonably accurate decisions without all the data providing you have some prior reference or experience to back it up. You are highly likely to be able to make everyday decisions at speed, although you may slow down where new activities are yet to be experienced.\n',
                        'People who have a propensity for balanced data and intuition are often seen as the voice of reason when it comes to group collaboration and joint decision making. Sometimes referred to as the glue within the organisation.'
                    ],
                    "band": 3
                },
                4: {
                    "title": "QUITE HIGH DATA",
                    "content": [
                        'Your self-assessment results suggest that you use reasonable amounts of data as your reliable source of reference when forming judgement. You clearly respect the value of evidence and fact-based material to support your considerations when reaching conclusions or making decisions.\n',
                        'It is likely that you appreciate being accurate, applying a level of due diligence but are prepared to back yourself if you don’t always have 100% of all the data. You are highly likely to be technically minded and naturally enjoy solving problems.\n',
                        'People who have a propensity for wanting quite high data often begin with the majority of supporting evidence and will then provide a brief sanity check through their own intuition to corroborate what the main facts are telling them.'
                    ],
                    "band": 4
                },
                5: {
                    "title": "HIGH DATA",
                    "content": [
                        'Your self-assessment results suggest that you use huge amounts of data as your primary source of reference when forming judgement. You clearly look for all the tangible evidence and fact-based material to support your considerations when reaching conclusions or making decisions.\n',
                        'It is likely that you place a high value on accuracy and are therefore naturally diligent when it comes to judgement and making recommendations. You are highly likely to be technically minded and naturally enjoy solving complex problems.\n',
                        'People who have a propensity for wanting high data are sometimes described as perfectionists and often like to take their time when reaching decisions to ensure situational accuracy. Be mindful though to know when you have collated enough data for the occasion and act.'
                    ],
                    "band": 5
                }
            },
            4: {
                5: {
                    "title": "HIGH CAUTIOUS",
                    "content": [
                        'Your self-assessment results suggest that you are highly cautious. You require a high level of certainty attached to any new activity. You also look towards safety and security to support new ideas. You are likely to enjoy the status quo, so the effort of being adventurous must also reflect the levels of reward.\n',
                        'It is likely that you may wait for things to develop and become much clearer before you embark on a new project or put yourself forward for a new role. You may also search for many strands of proof before you even contemplate relinquishing caution.\n',
                        'People who have a propensity to be overly cautious tend to be sceptical about the probability of an outcome. Adventure is often seen as misadventure. They also take considerable time to put their total trust into something or someone. People must earn your respect!'
                    ],
                    "band": 5
                },
                4: {
                    "title": "QUITE HIGH CAUTIOUS",
                    "content": [
                        'Your self-assessment results suggest that you are quite cautious in your general approach to things. You require a decent level of certainty attached to any new activity. You also look towards a reasonable level of safety and security to support new ideas. Though having said that, you throw yourself all-in when you get a sense that it’s right.\n',
                        'It is likely that you may hesitate slightly and allow things to develop with clarity before you embark on a new project or put yourself forward for a new role.\n',
                        'People who have a propensity to be quite cautious tend to be mildly sceptical about the probability of an outcome. They may also take a while to put their total trust into something or someone. People must earn your respect!'
                    ],
                    "band": 4
                },
                3: {
                    "title": "BALANCED ADVENTUROUS AND CAUTIOUS",
                    "content": [
                        'Your self-assessment results suggest that you strike a perfect balance between being adventurous and being cautious. You can evaluate the pros versus the cons to form judgement and make decisions which prevents you putting yourself in a position of high risk though by the same token you appreciate the lucrative benefits of a new experience.\n',
                        'It may be that at times you hold yourself back to ensure your thinking has evaluated the fine relationship between risk and reward. Be sure though not to miss the boat!\n',
                        'People who have a propensity for balanced adventurous and cautious are often seen as wise counsel when it comes to group collaboration and joint decision making, making great team players.'
                    ],
                    "band": 3
                },
                2: {
                    "title": "QUITE HIGH ADVENTUROUS",
                    "content": [
                        'Your self-assessment results suggest that you are quite highly adventurous, tending to think first of the benefits to be gained rather than dwell on what may be at risk or lost. Having said that you will not take on any new adventure without a cursory thought of what is at stake.\n',
                        'It is likely that you have expanded your comfort zone and know what it takes to push yourself further and have a decent tolerance for uncertainty. You will take on most new activities, though will apply a calculated assessment before you do.\n',
                        'People who are quite highly adventurous are often seen as courageous, appreciating that nothing ventured, then nothing gained. You are highly likely to seek reward in the pursuit of your new adventures.'
                    ],
                    "band": 2
                },
                1: {
                    "title": "HIGH ADVENTUROUS",
                    "content": [
                        'Your self-assessment results suggest that you are highly adventurous. You relish the enjoyment of new methods, bright ideas and brand-new experiences. You tend to consider the daring undertaking first rather than explore the potential risks. You enjoy the feelings created by being bold, daring, enterprising and intrepid.\n',
                        'It is likely that you may jump before you think, accepting the consequences, and have learned to move easily out of your comfort zone which has enabled a decent relationship with failure. It is rare that you will say no to any new adventure.\n',
                        'People who are highly adventurous are often seen as early pioneers and draw great strength from the rush of a new activity or experience, gaining new skills and knowledge in the process. You are highly likely to seek strong reward in the pursuit of your new adventures.'
                    ],
                    "band": 1
                }
            }
        };

        // Figure out the coordinates
        let pointSeparation = Math.round(wh / 12);
        let separationOffset = Math.round(wh / 60);
        let fromCentre = Math.round(wh / 20) + 0.5;

        // Calculate the coordinates depending upon the area
        // In theory(?!) this should make it easier to jiggle around the position of the chart and its size
        var pointDiffs;
        if (areaId === 3 || areaId === 4) {
            pointDiffs = [
                fromCentre, 
                fromCentre + (pointSeparation - separationOffset),
                fromCentre + (pointSeparation * 2 - separationOffset),
                fromCentre + (pointSeparation * 3 - separationOffset),
                fromCentre + (pointSeparation * 4 - separationOffset * 2)
            ];
            if (areaId === 3) {
                data[areaId][band]['high'] = {
                    x: xCentre,
                    y: yCentre - pointDiffs[band - 1]
                };
                data[areaId][band]['low'] = {
                    x: xCentre,
                    y: yCentre + pointDiffs[5 - band]
                };
            } else {
                data[areaId][band]['high'] = {
                    x: xCentre - pointDiffs[5 - band],
                    y: yCentre - 0.5
                };
                data[areaId][band]['low'] = {
                    x: xCentre + pointDiffs[band - 1],
                    y: yCentre - 0.5
                };
            }
        } else {
            pointDiffs = [
                fromCentre * Math.sin(Math.PI * 0.25),
                (fromCentre + (pointSeparation - separationOffset)) * Math.sin(Math.PI * 0.25),
                (fromCentre + (pointSeparation * 2 - separationOffset)) * Math.sin(Math.PI * 0.25),
                (fromCentre + (pointSeparation * 3 - separationOffset)) * Math.sin(Math.PI * 0.25),
                (fromCentre + (pointSeparation * 4 - separationOffset * 2)) * Math.sin(Math.PI * 0.25)
            ];
            if (areaId === 2) {
                data[areaId][band]['high'] = {
                    x: xCentre + pointDiffs[band - 1],
                    y: yCentre - pointDiffs[band - 1] - 0.5
                };
                data[areaId][band]['low'] = {
                    x: xCentre - pointDiffs[5 - band],
                    y: yCentre + pointDiffs[5 - band] - 0.5
                };
            } else {
                data[areaId][band]['low'] = {
                    x: xCentre + pointDiffs[5 - band],
                    y: yCentre + pointDiffs[5 - band]
                };
                data[areaId][band]['high'] = {
                    x: xCentre - pointDiffs[band - 1],
                    y: yCentre - pointDiffs[band - 1]
                };
            }
        }

        return data[areaId][band];
    }

    

    render() {

        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        return (
            <div>
                <button onClick={this.generatePDF} type="primary" className="download">Download the full report</button>
            </div>
        );
    }
}
export default Download;