import React, { Fragment } from 'react';

function ReportList(props){

    return (
        <Fragment>
            <section>
                <h2>Reports</h2>
                <ul>
                    <li>Report 1</li>
                    <li>Report 2</li>
                    <li>Report 3</li>
                </ul>
            </section>
  
        </Fragment>
    )
}

export default ReportList