import React from 'react';
import Radio from '../common/forms/radio';

function Question(props){

    return (
        <section>
            <p dangerouslySetInnerHTML={{__html:props.question.question}} />
            <div className="answers">
                <Radio value={1} id={props.question.id} handleChange={props.storeAnswer} activeAnswer={props.activeAnswer} label="Mostly Disagree" />
                <Radio value={2} id={props.question.id} handleChange={props.storeAnswer} activeAnswer={props.activeAnswer} label="Partially Disagree" />   
                <Radio value={3} id={props.question.id} handleChange={props.storeAnswer} activeAnswer={props.activeAnswer} label="No Opinion" />   
                <Radio value={4} id={props.question.id} handleChange={props.storeAnswer} activeAnswer={props.activeAnswer} label="Partially Agree" />   
                <Radio value={5} id={props.question.id} handleChange={props.storeAnswer} activeAnswer={props.activeAnswer} label="Mostly Agree" /> 
            </div>      
        </section>
    )
}

export default Question