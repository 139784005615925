// Core react and plugins
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

// Components
// import Loader from './../../common/loader';

// Component class itself
class Logout extends Component {

    render() {

        if (this.props.rdr) {
            return <Navigate to={ this.props.rdr } />
        }

        // return <Loader />;

    }
}

export default Logout;