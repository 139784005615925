import React, { Fragment } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Error from '../common/forms/error';
import moment from "moment";

function UserList(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }


    const getReportLightboxContent = (userReports) => {
        let lightboxViewContent = null;

        if(userReports.length > 0){
            console.log(userReports);
            lightboxViewContent = <Fragment>
                <ul className='report-links'>
                    {props.userReports.map( (report,i) => {
                        if (report.submissionDate !== "") {
                            return <li key={ 'rep-' + i }><Link to = {'/admin/reports/'+report.id} >Report submitted on { moment(report.submissionDate).format("DD/MM/YYYY") }</Link></li>
                        } else {
                            if (report.startDate !== "") {
                                return <li className='faded' key={'rep-' + i}>Unfinished self-assessment started on {moment(report.startDate).format("DD/MM/YYYY")}</li>
                            } else {
                                return <li className='faded' key={'rep-' + i}>Self-assessment not yet started</li>
                            }
                        }
                    } ) }
                </ul>
                <button className='sml-btn' onClick={(e) => props.resetUsr(e, userReports[0].userId) }>Reset</button>
            </Fragment>
        } else {
            lightboxViewContent = <p className="faded centred">This user has not yet completed the self-assessment.</p>;
        }
        return lightboxViewContent
    }

    const getPasswordResetLightboxContent = (passwordResetUrl) => {
        let lightboxViewContent = <Fragment>
            <p>You can send the link below to this user so they can reset their password for their account.</p>
            <p className="lnk-wrap">{passwordResetUrl}</p>
        </Fragment>
        
        return lightboxViewContent
    }
    
    let reportListPopup = null
    let lightboxViewTitle = '';
    let lightboxViewContent = null;

    if(props.lightboxView === 'report'){
        lightboxViewTitle = 'User Reports'
        lightboxViewContent = getReportLightboxContent(props.userReports);
    } else if(props.lightboxView === 'resetPassword'){
        lightboxViewTitle = 'User password reset'
        lightboxViewContent =   getPasswordResetLightboxContent(props.passwordResetUrl)
    }
    

    if(props.lightBoxOpen){
        reportListPopup = <div className="cover">
            <div className="hover-box">
                <Link to='' onClick={(e) => props.closeLightbox(e)} className="close-lnk">Close</Link>
                <h2>{lightboxViewTitle}</h2>
                { props.lbError && 
                    <div className={ props.lbError.class }>
                        <p>{ props.lbError.msg }</p>
                    </div>
                }
                {lightboxViewContent}
            </div>
        </div>
    }
    
    return (
        <Fragment>
            {props.userPermissions.Users && <Link to='/admin/manage/users/upload' className="btn minor faded">Upload user CSV</Link>}
            {props.userPermissions.Users && <Link to='/admin/manage/users/create' className="btn minor">Create user</Link>}
            <h1>Manage users</h1>

            <div className='width-9'>
                {reportListPopup}

                <Error error={props.error}/>
                <table>
                    <thead>
                        <tr>
                            <th>First name</th>
                            <th>Last name</th>
                            <th className='mid-hide'>Email</th>
                            <th className='mid-hide'>Phone number</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        { typeof props.users != "undefined" && props.users.map( (user,i) => {
                            return (
                                <tr key={'tr-'+i}>
                                    <td>{user.FirstName}</td>
                                    <td>{user.LastName}</td>
                                    <td className='mid-hide'>{user.Email}</td>
                                    <td className='mid-hide'>{user.MobileNo}</td>
                                    <td>
                                        <Link onClick={(e) => props.getUserReports(user.Ref,e)} to='' className="action-btn">Reports</Link>
                                        {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.resetUsrPw(user.Ref, e)} className="action-btn sml-hide">Pwd</Link>: null }
                                        {!user.hideLink && props.userPermissions.Users ? <Link to={'/admin/manage/users/'+user.Ref} className="action-btn">Edit</Link> : null }
                                        {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.deleteUser(user.Email, e)} className="action-btn red sml-hide">Delete</Link>: null }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                    
            </div>

            <div className="width-3 filter">
                <h4>Filter users</h4>

                <div className="input text">
                    <label htmlFor='searchInput'>Filter by name</label>
                    <input type="text" id="searchInput" onChange={ (e) => props.setSearchEntry(e.target.value) }/>
                </div>

                <div className="input select">
                    <label htmlFor='clientInput'>Filter by client</label>
                    <select id="clientInput" onChange={(e) => props.setClient(e.target.value)}>
                        <option value="0"></option>
                        { props.clients.map ( (client, i) =>
                            <option value={ client.id } key={ "copt-" + i }>{ client.clientName }</option>                            
                        )}
                    </select>
                </div>

                <div className="input select">
                    <label htmlFor='managerInput'>Filter by manager</label>
                    <select id="managerInput" onChange={(e) => props.setManager(e.target.value)}>
                        <option value="0"></option>
                        {props.managers.map((manager, i) =>
                            <option value={manager.ID} key={"copt-" + i}>{manager.FirstName} {manager.LastName}</option>
                        )}
                    </select>
                </div>

                <button onClick={ () => props.loadUsers() }>Filter</button>
            </div>
        </Fragment>
    )
}

export default UserList