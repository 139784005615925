export const actions = {
    // Authentication options
    storeSession: ( store, sessionInfo ) => {
        store.setState({ 
            apiToken: sessionInfo.apiToken,
            apiKey: sessionInfo.apiKey,
            client: sessionInfo.client,
            email: sessionInfo.email,
            firstName: sessionInfo.firstName,
            lastName: sessionInfo.lastName,
            manager: sessionInfo.manager,
            mobileNo: sessionInfo.mobileNo,
            permissions: sessionInfo.permissions,
            ref:sessionInfo.ref
        });
    },
    updateSession: (store, sessionInfo) => {
        store.setState({
            firstName: sessionInfo.firstName,
            lastName: sessionInfo.lastName
        });
    },
    clearAuth: (store, sessionInfo) => {
        store.setState({
            apiToken: false,
            apiKey: false,
            client: false,
            email: false,
            firstName: false,
            lastName: false,
            manager: false,
            mobileNo: false,
            permissions: false,
            ref: false
        });
    },
    // Set the timezone in the global store
    setTimezone: (store, zone) => {
        // Get the item
        store.setState({ timezone: zone });
    },
};