import React, { useEffect, useState } from 'react';
import ManagerAutocomplete from '../../components/common/manager-autocomplete';
import { useCookies } from 'react-cookie';
import useFlash from '../../store/flash';

import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';

function ManagerAutocompleteContainer(props){

    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [loading, setLoading] = useState(true);// eslint-disable-line
    const [rdr, setRdr] = useState(false);// eslint-disable-line
    const [managers, setManagers] = useState([]);
    const [localSelectedManager, setLocalSelectedManager] = useState({});
    const [flashState, flashActions] = useFlash();// eslint-disable-line
    const [authCookie] = useCookies(['jdm']);
    const [showDropDown,toggleShowDropDown] = useState(false);
    const [searchStr,setSearchStr] = useState("")
    const [valErrors, setValErrors] = useState(false);
    const [clientId,setClientId] = useState(-1)
    // const [searchStr,setSearchStr] = useState('')

    const suggestionCount = 5;
    
    const loginUrl = '/'
     // Check that we have a partially complete auth status (based upon the cookie)
     let loadManagers = (searchStr) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let clientId = props.clientId

        let data ={
            nameString:searchStr
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/managers/' + clientId, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            // Set the state for the users
            setManagers(data.slice(0,suggestionCount))
            setLoading(false)
            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    let createManager = (strInput) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let managerNames = searchStr.split(' ');

        let data ={
            firstName:managerNames[0],
            lastName:managerNames[1],
            clientId:parseInt(clientId)
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/managers', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            if(data.rslt === 'success'){
                let localManager = {
                    ID:data.detail,
                    FirstName: managerNames[0],
                    LastName: managerNames[1]
                }

                setSelectedManager(localManager)
            } else {
                setValErrors({
                    
                        'manager':['This manager aleady exists.  Please select from the autocomplete']
                })
            }
            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    const setSelectedManager = (manager) =>{
        setValErrors({})
        setLocalSelectedManager(manager)
        setSearchStr(manager.FirstName + ' ' + manager.LastName)
        if(typeof props.setUserManager !== "undefined"){
            props.setUserManager(manager)
        }
        toggleShowDropDown(false)

    }

    const clearSelectedManager = () =>{
        setValErrors({})
        setLocalSelectedManager({})
        setSearchStr('')
        if(typeof props.setUserManager !== "undefined"){
            props.setUserManager({})
        }
        toggleShowDropDown(false)

    }

    const handleChange = (e)=>{
        if(clientId === -1){
            setSearchStr(e.target.value)
            setValErrors({'manager':['Please select a client before completing this field']})
        } else {
            setValErrors(false)
            toggleShowDropDown(true)
            loadManagers(e.target.value)
            setSearchStr(e.target.value)
        }
        
    }

    const handleBlurEvent = (event)=>{
        if (!event.currentTarget.contains(event.relatedTarget)) {
            toggleShowDropDown(false);
        }
    }

    const handleReturnKeyPress = (e) => {
        if (e.keyCode === 13) {
            createManager(searchStr)
        }
    }

    const handleNewManagerSelect = () => {
        createManager(searchStr)
    }

    /* eslint-disable */
    useEffect(() => {
        loadManagers()
    },[]);

    useEffect(() => {

        if(typeof props.userManager != "undefined" && typeof props.userManager.ID != "undefined"){
            setSelectedManager(props.userManager )
        }

    },[props.userManager]);

    useEffect(() => {

        if(props.clientId > -1){
            setClientId(props.clientId)
            clearSelectedManager()
        }
        
    },[props.clientId]);
    /* eslint-enable */

    return (
        <ManagerAutocomplete 
            managers={managers}
            setSelectedManager={setSelectedManager}
            localSelectedManager={localSelectedManager}
            handleChange={handleChange}
            searchStr={searchStr}
            setSearchStr={setSearchStr}
            handleBlurEvent={handleBlurEvent}
            showDropDown={showDropDown}
            handleReturnKeyPress={handleReturnKeyPress}
            valErrors={valErrors}
            handleNewManagerSelect={handleNewManagerSelect}
            label={ props.label }
        />
    )
}

export default ManagerAutocompleteContainer