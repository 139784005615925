// Core react and plugins
import React, {  useEffect, useState } from 'react';
import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';

import Questionnaire from '../../components/questionnaire/question';

// // State management
import { useCookies } from 'react-cookie';

const QuestionnaireContainer = (props) => {
    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [rdr, setRdr] = useState(false);// eslint-disable-line
    const [activeAnswer,setActiveAnswer] = useState(-1)
    const [authCookie] = useCookies(['jdm']);

    const storeAnswer = (e) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let questionId = props.question.id
        let response = e.target.value
        let data = {
            questionId: parseInt(questionId),
            response: parseInt(response)
        }

        
        fetch(process.env.REACT_APP_API_BASE + '/questions', {
            method: 'post',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey,
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success'){
                setActiveAnswer(response)
            }

            if(typeof props.markQuestionAnswered !== 'undefined'){
                props.markQuestionAnswered(questionId,parseInt(response))
            }
            
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    useEffect(() => {
        setActiveAnswer(0)
        if(typeof props.question !== 'undefined' && props.question.pastResponse > 0){
            setActiveAnswer(props.question.pastResponse)
        }
    },[props.question])
   

    return (
        <Questionnaire question={props.question} storeAnswer={storeAnswer} activeAnswer={activeAnswer}/>
    );
}

export default QuestionnaireContainer;