import React, { Fragment } from 'react';

function Radio(props){

    return (
        <Fragment>
            <div className="input radio">
                <input type="radio" id={props.id + '-' + props.value} name={props.name} onChange={props.handleChange} value={props.value} checked={parseInt(props.activeAnswer) === parseInt(props.value)} />
                <label htmlFor={props.id + '-' + props.value}>{props.label}</label>
            </div>
        </Fragment>
    )
}

export default Radio
