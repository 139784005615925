import React from 'react';

// Assets
import Logo from './../../assets/img/abstractuk-logo.png';

function Footer(props){

    return (
        <footer>
            <div className="content">
                <p className='powered'>Powered by:</p>
                <img src={Logo} className="logo" alt="ABSTRACT" />                
                <p>&copy; 2022 ABSTRACT</p>
            </div>
        </footer>
    )
}

export default Footer