import React from 'react';
import MenuContainer from '../../containers/questionnaire/menu-container';
import QuestionContainer from '../../containers/questionnaire/question-container';
import Error from '../../components/common/forms/error';
import { Navigate } from 'react-router-dom';

function Section(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    let headClass = "sxn-head ";
    let qClass = "questions ";
    if (props.sxnSlug === "1") {
        headClass += "blue";
        qClass += "blue";
    }
    if (props.sxnSlug === "2") {
        headClass += "yellow";
        qClass += "yellow";
    }
    if (props.sxnSlug === "3") {
        headClass += "red";
        qClass += "red";
    }

    return (
        <div className='content q-core'>
            <section className='width-12'>
                <h1 className={ headClass }>{props.sectionTitle}</h1>

                <ul className="cb-labels">
                    <li>Mostly Disagree</li>
                    <li>Partially Disagree</li>
                    <li>No Opinion</li>
                    <li>Partially Agree</li>
                    <li>Mostly Agree</li>
                </ul>

                <Error error={props.error} />

                <div className={ qClass }>
                    {props.questions.map( (question,i) => {
                        return <QuestionContainer key={i}  value={i+1} question={question} markQuestionAnswered={props.markQuestionAnswered}/>
                    })}
                </div>
            
                <MenuContainer sectionMap={props.sectionMap} {...props}  />
            </section>
        </div>
    )
}

export default Section