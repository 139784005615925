/* 
    Error flash message
*/
// Core react and plugins
import React, { Component, Fragment } from 'react';

// Component class itself
class ValidationError extends Component {

    
    render() {

        if (!this.props.errors || typeof this.props.errors[this.props.fieldName] == "undefined") {
            return <Fragment></Fragment>
        }

        return (

            <div className="val-msg">
                { this.props.errors[this.props.fieldName].map ( (err, i) => 
                    <p key={ "err-" + i }>{ err }</p>
                )}
            </div>

        );

    }
}

export default ValidationError;
