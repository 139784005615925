import React, { Component } from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom'
import LoginContainer from './../containers/users/auth/login-container';
import LogoutContainer from './../containers/users/auth/logout-container';
import ForgottenPwContainer from './../containers/users/auth/forgotten-pw-container';
import SetPwContainer from './../containers/users/auth/set-pw-container';

import Logo from './../assets/img/neurosphere-logo.svg';

class Routing extends Component {
 
    render () {
        if (this.props.rdr) {
            return <Navigate  to={ this.props.rdr } />
        }
       
        return (
            <div className="login">
                <div className="half green">
                    <div className="shard-one"></div>
                    <div className="shard-two"></div>
                    <img src={ Logo } alt="NeuroSphere logo" />
                </div>
                <div className="half">
                    <div className="login-content">
                        <h1>Judgement and decision making</h1>
                        <Routes>
                            <Route path='forgotten-password' element={ <ForgottenPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey/:setType' element={ <SetPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey' element={ <SetPwContainer/> } />
                            <Route path='logout' element={ <LogoutContainer/> } />
                            <Route index element={<LoginContainer/>} />
                            
                        </Routes>
                    </div>
                </div>
            </div>
        );
    }
}

export default Routing;
