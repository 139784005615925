// Core react and plugins
import React, {  useState,useEffect } from 'react';
import Header from '../../components/common/header';
import CryptoJS from 'crypto-js';

// // State management
import { useCookies } from 'react-cookie';

const HeaderContainer = (props) => {
    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [rdr, setRdr] = useState(false); // eslint-disable-line
    const [authCookie] = useCookies(['jdm']);
    const [permissions,setPermissions] = useState({})
    

    const getUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        setPermissions(reformData.permissions)
    }

    /* eslint-disable */
    useEffect(() => {
        getUserPermissions()
    },[])
   /* eslint-enable */

    return (
        <Header 
            permissions={permissions}
        />
    );
}

export default HeaderContainer;