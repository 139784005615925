import React, { Fragment } from 'react';

import IntroLady from './../../assets/img/intro-lady.png';
import DriversMan from './../../assets/img/drivers-man.png';
import { Link } from 'react-router-dom';

function Intro(props){

    return (
        <Fragment>
            <section className="intro">
                <div className="shard-one"></div>
                <div className="shard-two"></div>
                <div className="content">
                    <div className="width-6 wording">
                        <h1>Judgement and Decision Making</h1>
                        <p>We are making judgements and decisions every day of our lives. Some we are aware of, others less so. Some judgements and decisions have minor repercussions, others major.</p>
                        <p>The purpose of this project is to help you to understand the four key drivers behind your judgement and decision making. You&rsquo;ll start by completing a self-assessment that will help to identify your drivers and produce your personalised NeuroSphere.</p>
                        <p>You will then explore those drivers in more detail. This includes how to maximise your drivers and how to avoid potential pitfalls.</p>
                    </div>
                    <div className="width-6 lady">
                        <img src={ IntroLady } alt="A happy business-lady" className={ props.fadeClass } onLoad={ () => props.setFadeClass("visible") } />
                    </div>
                </div>
            </section>

            <section className="drivers">
                <div className="content">
                    <div className="width-12 wording">
                        <h2>The Four Main Drivers</h2>
                        <p>The NeuroSphere of Judgement and Decision Making is comprised of four main drivers:</p>
                    </div>

                    <div className="width-6 driver blue">
                        <h6 className='ball-no'>1</h6>
                        <h3>Risk</h3>
                        <p>Your propensity for risk has a direct impact on the judgements and decisions you make. We are all somewhere on the risk scale, from those who are risk averse to those who thrive on risk taking.</p>
                    </div>
                    <div className="width-6 driver yellow">
                        <h6 className='ball-no'>2</h6>
                        <h3>Control</h3>
                        <p>How much control you like to have impacts how you make judgements and decisions. We are all somewhere between &quot;control freaks&quot; and completely &quot;hands-off&quot;.</p>
                    </div>
                    <div className="width-6 driver pink">
                        <h6 className='ball-no'>3</h6>
                        <h3>Data / Intuition</h3>
                        <p>Judgement and decision making relies on having information and using your instincts/experience. Your preference for one over the other impacts how you make judgements and decisions.</p>
                    </div>
                    <div className="width-6 driver grey">
                        <h6 className='ball-no'>4</h6>
                        <h3>Cautious / Adventurous</h3>
                        <p>We are naturally somewhere on the spectrum of caution and adventure. How cautious or adventurous you are will influence the judgements and decisions you make.</p>
                    </div>
                </div>
            </section>

            <section className="description">
                <div className="content">
                    <div className="width-6">
                        <img src={DriversMan} alt="A satisfied employee who has taken the self-assessment" className={props.fadeClassBtm} onLoad={() => props.setFadeClassBtm("visible")} />
                    </div>

                    <div className="width-6 wording">
                        <h2>Judgement and decision making self-assessment</h2>
                        <p>To follow are 60 statements. 20 statements are general and you should grade how much you agree or disagree with each one. 20 statements are about what your manager or peers think about you, and 20 are about what other people think about you. Again, grade how much you agree or disagree with each one.</p>
                        <p>Answer honestly. Go with your instinctive reaction. There is no correct or incorrect way to grade each statement. Answer all questions in the context of your work life.</p>
                        <p>Click the button below to begin the self-assessment.</p>
                        <Link to={props.startLink} className="btn">Begin Your Self-assessment</Link>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Intro