import React, {  Fragment } from 'react';

function Password(props){

    return (
        <Fragment>
            <div className="input password">
                <label htmlFor={props.id}>{props.label}</label>
                <input type="password" id={props.id} autoComplete="password" onChange={props.handleChange} />
            </div>
        </Fragment>
    )
}

export default Password
