// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Password from '../../common/forms/password';
import TextInput from '../../common/forms/text-input';
import Button from '../../common/forms/button';

// Components
import Error from './../../../components/common/forms/error';

// Assets
// import ColourLogo from './../../../assets/img/login/colour-logo.svg';

// Component class itself
class Login extends Component {

    componentDidMount() {
        document.title = 'Sign in :: NeuroSphere';
    }

    render() {

        if ( this.props.rdr ) {
            return <Navigate to={ this.props.rdr } />
        }

        return (

            <Fragment>
                {/* <img src={ColourLogo} alt="CSIRT Commonwealth" className="login-logo-sml" /> */}
                <h2>Sign in</h2>

                <Error error={ this.props.error } />

                <form>
                    {/* <div className="input text">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" autoComplete="username" onChange={ (e) => this.props.handleAuthInfo("username", e) } />
                    </div> */}
                    <TextInput label='Email' id='email' handleChange={(e) => this.props.handleAuthInfo('email',e) } />
                    <Password label='Password' id='password' handleChange={(e) => this.props.handleAuthInfo('password',e)} />
                    {/* <div className="input password">
                        <label htmlFor="username">Password</label>
                        <input type="password" id="pw" autoComplete="password" onChange={(e) => this.props.handleAuthInfo("password", e)} />
                    </div> */}
                    {/* <Select id='select-test' options={[{value:1,label:'One'},{value:2,label:'2'}]} default_value={1} /> */}
                    <Button handleClick={ (e) => this.props.loginFunc(e) } btnTxt={ this.props.btnTxt }/>

                </form>

                <p className="sml">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
            </Fragment>

        );

    }
}

export default Login;