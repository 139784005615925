/* 
    Error flash message
*/
// Core react and plugins
import React, { Component, Fragment } from 'react';

// Component class itself
class Error extends Component {

    render() {

        if (!this.props.error) {
            return <Fragment></Fragment>
        }

        if (typeof this.props.sml != 'undefined' && this.props.sml) {
            return (

                <div className={"flash-msg sml " + this.props.error.style}>
                    { this.props.error.msg}
                </div>

            );
        }

        return (

            <div className={ "flash-msg " + this.props.error.style }>
                { this.props.error.msg }
            </div>

        );

    }
}

export default Error;
