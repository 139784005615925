import React, { Fragment, Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import FooterContainer from '../containers/common/footer-container';
import HeaderContainer from '../containers/common/header-container';
import IntroContainer from '../containers/questionnaire/intro-container';
import SectionContainer from '../containers/questionnaire/section-container';
import ReportContainer from '../containers/reports/admin/report-container';
import ReportListContainer from '../containers/reports/admin/report-list-container';
import Errors from './common/errors';
import AdminManageContainer from '../containers/users/admin-manage-container';
import SettingsContainer from '../containers/users/auth/change-details-container';

class AuthRouting extends Component {

    render () {
        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        return (
            <Fragment>
                    {/* <HeaderContainer {...this.props}/> */}
                <HeaderContainer />
                    <div className="main-content">
                        <Routes>
                            <Route path='/intro' element={<IntroContainer />} />
                            <Route exact path='/questionnaire/' element={<SectionContainer />} />
                            <Route exact path='/questionnaire/:sectionSlug/:areaSlug' element={<SectionContainer />} />
                            <Route exact path='/my-report' element={<ReportContainer />} />
                            <Route exact path='/admin/reports' element={<ReportListContainer />} />
                            <Route exact path='/admin/reports/:reportSlug' element={<ReportContainer />} />
                            <Route exact path='/settings' element={<SettingsContainer/>}/>
                            <Route exact path='/admin/manage/*' element={<AdminManageContainer/>}/>
                            <Route path='*' element={<Errors/>} /> 
                        </Routes>
                    </div>

                <FooterContainer/>
            </Fragment>
        );
    }
}

export default AuthRouting;
