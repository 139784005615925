import React, { Fragment } from 'react';

import { Navigate } from 'react-router-dom';
import ValidationError from '../../common/forms/validation-error';
import Button from '../../common/forms/button';
import TextInput from '../../common/forms/text-input';

function CreateClient(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    const pageName = (!props.isClientEdit) ? 'Client a new client' : 'Edit client details';
    const btnText = props.btnTxt;

    return (
        <Fragment>
            <section className="width-9 centred">
                <h1>{pageName}</h1>
                <TextInput label='Name' id='name' value={props.settings.name} handleChange={(e) => props.handleUpdate("name",e)} />
                <ValidationError errors={props.valErrors} fieldName='name'/>
                <Button btnTxt={btnText} id='save_btn' handleClick={props.saveClient}/>
            </section>
  
        </Fragment>
    )
}

export default CreateClient