import globalHook from "use-global-hook";

import * as actions from "../actions/flash";

const initialState = {
    msg: false,
    style: false
};

const useGlobal = globalHook(initialState, actions.actions);

export default useGlobal;
