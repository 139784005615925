import React, { Fragment } from 'react';
import Button from '../common/forms/button';
import TextInput from '../common/forms/text-input';
import { Link, Navigate } from 'react-router-dom';
import Checkbox from '../common/forms/checkbox';
import ManagerAutocompleteContainer from '../../containers/common/manager-autocomplete-container';
import Select from '../common/forms/select';
import ValidationError from '../common/forms/validation-error';
import Error from '../common/forms/error';

function User(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    let emailLinkPopup = null

    if(props.emailLink && props.lightBoxOpen){
        emailLinkPopup = <div className="cover">
            <div className="hover-box">
                <Link to='/admin/manage/users' className="close-lnk">Close</Link>
                <h2>Invite Link</h2>
                <div className="flash-msg grn">
                    The user has been created successfully.
                </div>
                <p>You can send the link below to the new user so they can set up a password for their account.</p>
                <p className="lnk-wrap">{props.emailLink}</p>
            </div>
        </div>
    }

    return (
        <Fragment>
            {emailLinkPopup}
            <section className='width-9 centred'>

                <h1>{props.isUserEdit ? 'Edit user details' : 'Create a new user'}</h1>

                <Error error={props.error}/>
                
                <TextInput label='First Name' id='firstName' value={props.settings.firstName} handleChange={(e) => props.handleUpdate('firstName',e) }/>
                <TextInput label='Last Name' id='lastName' value={props.settings.lastName} handleChange={(e) => props.handleUpdate('lastName',e) }/>
                <TextInput label='Email' id='email' value={props.settings.email} handleChange={(e) => props.handleUpdate('email',e) }/>
                <ValidationError errors={props.valErrors} fieldName='email'/>
                <TextInput label='Phone' id='phone' value={props.settings.phone} handleChange={(e) => props.handleUpdate('phone',e) }/>

                <Select id='select-test' label='Clients' options={props.clientOptions} handleChange={(e) => props.handleClientChange(e)} default_value={props.clientId} />
                <ManagerAutocompleteContainer setUserManager={props.setUserManager} userManager={props.userManager} clientId={props.clientId} label="Manager" errors={props.valErrors} />
                
                <ValidationError errors={props.valErrors} fieldName='client' />

                <div className="permissions">
                    <h6>User permissions</h6>
                    <Checkbox label='Take the self-assessment' id='Questionnaire' checked={props.permissions['Questionnaire']} handleChange={(e) => props.handlePermissionsUpdate('Questionnaire', e)} />
                    <Checkbox label='View all reports' id='reports' checked={props.permissions['Reports']} handleChange={(e) => props.handlePermissionsUpdate('Reports',e)}/>
                    <Checkbox label='Manage users' id='Users' checked={props.permissions['Users']}  handleChange={(e) => props.handlePermissionsUpdate('Users',e)}/>
                    <Checkbox label='Update clients and managers' id='Client' checked={props.permissions['Client']}  handleChange={(e) => props.handlePermissionsUpdate('Clients',e)}/>
                </div>

                {! props.isUserEdit ?
                <Checkbox label='Display the invitation link, so I can email it to the user myself' id='return_link' handleChange={(e) => props.setReturnEmailLink(e.target.checked)}/>
                 : null }
                <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.saveUser}/>
            </section>
  
        </Fragment>
    )
}

export default User