import globalHook from "use-global-hook";

import * as actions from "./../actions/auth";

const initialState = {
    apiKey: false,
    apiToken: false,
    client:false,
    email:false,
    firstName:false,
    lastName:false,
    manager:false,
    mobileNo:false,
    permissions:false,
    ref:false
};


const useGlobal = globalHook( initialState, actions.actions);

export default useGlobal;
