import React from 'react';
import { Link } from 'react-router-dom';

function Menu(props){

    let nextLink = null
    let prevLink = null

    if (props.nextLink !== -1) {
        if (props.nextLink.indexOf('my-report') === -1) {
            nextLink = <Link to={'#'}  onClick={(e) => props.handleNavigate(e,'/questionnaire/' + props.nextLink)} className="q-btn nxt">Next</Link>
        } else {
            nextLink = <Link to={'#'} onClick={(e) => props.submitQuestionnaire(e)}  className="q-btn submit">Submit</Link>
        }
    }

    if (parseInt(props.prevLink) !== -1) {

        prevLink = <Link to={'/questionnaire/' + props.prevLink} className="q-btn prev">Prev</Link>

    }

    return (
        <nav className='q-menu'>
            { nextLink }
            { prevLink }

            <ul>
                {props.sections.map((section,i) => {
                    // Figure out what the cuirrent state is
                    let groupState = "";
                    if (parseInt(props.sxnSlug) === section.sxnId && parseInt(props.areaSlug) === section.areaId) groupState = "current";
                    if (parseInt(props.navigationIndex) > i) groupState = "past";

                    if (parseInt(section.totalQuestions) === parseInt(section.answeredQuestions)) groupState += " completed";
                    
                    for(let j =0; j < props.incompleteSections.length;j++){
                        if(section.sxnId === props.incompleteSections[j].sectionId && section.areaId === props.incompleteSections[j].areaId){
                            groupState += " incomplete"
                        }
                    }

                    return (
                        <li key={'menu-'+i} className={ groupState }><Link onClick={e => props.handleNavigate(e,'/questionnaire/'+section.sxnId+'/'+section.areaId)}   to={'/questionnaire/'+section.sxnId+'/'+section.areaId}>{section.sxnTitle}: {section.areaTitle}</Link></li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Menu