// Core react and plugins
import React from 'react';
import ReportList from '../../../components/report/admin/report-list';

const ReportListContainer = (props) => {
    return (
        <ReportList/>
    );
}

export default ReportListContainer;