/* 
    Error view
    
    Props:
        - errorType: If no error is passed then defaults to a 404 Unfound error
*/
// Core react and plugins
import React, { Component } from 'react';

// Assets


// Components


// Component class itself
class Errors extends Component {

    render() {

        return (

            <div className="usr-settings">
                <div className="usr-settings-head">
                    <div className="shard-one">&nbsp;</div>
                    <div className="shard-two">&nbsp;</div>
                </div>
                <div className="width-6 centred">
                    <h1>Page not found</h1>
                    <p>It may be our poor judgement, but we can&rsquo;t find the page you&rsquo;re looking for.</p>
                    <p>Please go back and try again</p>
                </div>
            </div>

        );

    }
}

export default Errors;