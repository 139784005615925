import React from 'react';
import { Link } from 'react-router-dom';

// Assets
import Logo from './../../assets/img/neurosphere-logo.svg';

function Header(props){

    return (
        <header>
            <div className="content">
                <div className='width-3'>
                    <img src={Logo} className="logo" alt="NeuroSphere logo" />                
                </div>
                <div className='width-9'>
                    <nav>
                        <ul>
                            {props.permissions.Questionnaire &&
                                <li><Link to='/intro'>Self-Assessment</Link></li>
                            }
                            <li><Link to='/settings'>Settings</Link></li>
                            {props.permissions.Reports &&
                                <li><Link to='/admin/manage'>Manage</Link></li>
                            }
                            <li><Link to='/u/logout'>Logout</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header