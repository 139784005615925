import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Error from '../../common/forms/error';

function ClientList(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <Fragment>
            <section className="width-9 centred">
                <Link to='/admin/manage/clients/create' className='btn minor'>Create</Link>
                <h1>Manage clients</h1>
                
                <Error error={ props.error } />
                <table>
                    <thead>
                        <tr>
                            <th>Client name</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            typeof props.clients != "undefined" && props.clients.map( (client,i) => {
                                
                                return (
                                    <tr key={'tr-'+i}>
                                        <td>{client.clientName}</td>
                                        <td>
                                            <Link to={'/admin/manage/clients/'+client.id} className="action-btn">Edit</Link>
                                            <Link to='#' onClick={(e) => props.deleteClient(client.id,e)} className="action-btn red">Delete</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </section>
  
        </Fragment>
    )
}

export default ClientList