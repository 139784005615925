// Core react and plugins
import React from 'react';

import ResultGraph from '../../../components/report/admin/result-graph';


const ResultGraphContainer = (props) => {

    //store end coordinate strings
    let coordinateDataSet = [];

    // Set up the base values for the scaling
    let wh = 650;
    let pointSeparation = Math.round(wh / 11);
    let separationOffset = Math.round(wh / 60);
    let fromCentre = Math.round(wh / 20) + 0.5;
    const xCentre = 348 //from 0 at left 
    const yCentre = 320 //from 0 at top 

    // First of all - figure out the starting position of the dots (in the centre)
    // Helper values to calculate x and y point values
    const pointCount = 8;

    // HTML strings to pass to svg animate values
    let dataOriginStr =""
    for(let j = 0 ; j < pointCount ; j++){
        //set all the points in the centre on load
        dataOriginStr += xCentre+','+yCentre+','
    }

    // Next figure out the final positions of the dots
    let dataPositionStr = "";
    
    // Set up the distances from the centre that the point should be
    const pointDiffs = [
        fromCentre,
        fromCentre + (pointSeparation - separationOffset),
        fromCentre + (pointSeparation * 2 - separationOffset),
        fromCentre + (pointSeparation * 3 - separationOffset),
        fromCentre + (pointSeparation * 4 - separationOffset * 2)
    ];
    var pointDiffsDiagonal = [];
    var diagDistance;
    for (var k in pointDiffs) {
        diagDistance = pointDiffs[k] * Math.sin(Math.PI * 0.25);
        pointDiffsDiagonal.push(diagDistance);
    }

    // Figure out where the individual points should therefore be
    var x;
    var y;
    var band;
    for (var i in props.data) {

        band = props.data[i].band;

        switch (props.data[i].areaId) {
            case 4:
                // Cautious / adventurous sits on the X-axis
                // Firstly the high points
                y = yCentre;
                x = xCentre - pointDiffs[5 - band];
                coordinateDataSet[7] = x + ',' + y;

                // Secondly the low points
                x = xCentre + pointDiffs[band - 1];
                coordinateDataSet[3] = x + ',' + y;
                break;

            case 3: 

                // Firstly the high points
                // Data / intuition sits on the Y-axis
                y = yCentre - pointDiffs[band - 1];
                x = xCentre;
                coordinateDataSet[1] = x + ',' + y;

                // Secondly the low points
                y = yCentre + pointDiffs[5 - band];
                coordinateDataSet[5] = x + ',' + y;

                break;

            case 2: 

                // Control site on the upwards diagonal
                // Firstly the high points
                y = yCentre - pointDiffsDiagonal[band - 1];
                x = xCentre + pointDiffsDiagonal[band - 1];
                coordinateDataSet[2] = x + ',' + y;

                // Secondly the low points
                x = xCentre - pointDiffsDiagonal[5 - band];
                y = yCentre + pointDiffsDiagonal[5 - band];
                coordinateDataSet[6] = x + ',' + y;

                break;

            default: 

                // Risk sits on the downwards diagonal
                // Firstly the high points
                y = yCentre - pointDiffsDiagonal[band - 1];
                x = xCentre - pointDiffsDiagonal[band - 1];
                coordinateDataSet[0] = x + ',' + y;

                // Secondly the low points
                x = xCentre + pointDiffsDiagonal[5 - band];
                y = yCentre + pointDiffsDiagonal[5 - band];
                coordinateDataSet[4] = x + ',' + y;
                break;

        }

    }

    //assemble endpoints into HTML string
    dataPositionStr = coordinateDataSet.join(',')

    return (
        <ResultGraph dataOriginStr={dataOriginStr} dataPositionStr={dataPositionStr}/>
    );
}

export default ResultGraphContainer;