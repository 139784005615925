// Core react and plugins
import React, {  useEffect, useState } from 'react';
import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';
import Intro from '../../components/questionnaire/intro';

// // State management
import { useCookies } from 'react-cookie';


const IntroContainer = (props) => {
    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [rdr, setRdr] = useState(false);// eslint-disable-line
    const [fadeClass, setFadeClass] = useState("");// eslint-disable-line
    const [fadeClassBtm, setFadeClassBtm] = useState("");// eslint-disable-line
    const [authCookie] = useCookies(['jdm']);
    const [startLink,setStartLink] = useState('/questionnaire');
    
    const checkQuestionnaireStatus = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        
        fetch(process.env.REACT_APP_API_BASE + '/reports/check', {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'take-questions'){
                setStartLink('/questionnaire')
            } else {
                setStartLink('/my-report')
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }
   
    /*eslint-disable */
    useEffect(() => {
        document.title = 'Welcome :: Judgement and Decision Making :: NeuroSphere';
        checkQuestionnaireStatus()
    },[])
    /*eslint-enable */

    return (
        <Intro 
            startLink={startLink}
            setFadeClass={ setFadeClass }
            fadeClass={fadeClass}
            fadeClassBtm={ fadeClassBtm }
            setFadeClassBtm={ setFadeClassBtm }
        />
    );
}

export default IntroContainer;