import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ValidationError from './forms/validation-error';

function ManagerAutocomplete(props){

    return (
        <Fragment>
            <div onBlur={(e) => props.handleBlurEvent(e)}>
                <div className="input text">
                    <label htmlFor={props.id}>{ props.label }</label>
                    <input type="text" id={props.id}  onChange={(e) => props.handleChange(e)} onKeyUp={(e) => props.handleReturnKeyPress(e)} value={ props.searchStr }/>
                </div>

                { props.showDropDown && 
                    <ul className="autocomplete">
                        {typeof props.managers != 'undefined' && props.managers.length > 0 &&
                            <Fragment>
                                {props.managers.map( (manager, i) => 
                                    <li key={'manager-' + i} >
                                        <Link to='#' onClick={(e) => props.setSelectedManager(manager)} >{manager.FirstName + ' ' + manager.LastName}</Link>
                                    </li>
                                )}
                            </Fragment>
                        }
                        <li key={'manager-new'} >
                                <Link to='#' onClick={props.handleNewManagerSelect} className='autocomplete-create'>{"Press Enter to create new manager \"" + props.searchStr + "\""}</Link>
                            </li>
                    </ul>
                }

                <ValidationError errors={props.valErrors} fieldName='manager'/>
            </div>
        </Fragment>
    )
}

export default ManagerAutocomplete