// Core react and plugins
import React from 'react';
import { Navigate } from 'react-router-dom';

/*
options take format 
[
    {value:1,label:'One'}
]
*/
function Select (props){

    if (props.rdr) {
        return <Navigate to={ props.rdr } />
    }

    return (

        <div className="input select">
            <label htmlFor={props.id} className='select'>{props.label}</label>
            <select id={props.id} onChange={(e) => props.handleChange(e)} value={typeof props.default_value != 'undefined' ? props.default_value : -1}>
                <option key={'opt-default'} value={-1}  readOnly={true} disabled>Choose</option>
                {props.options.map((opt, j) =>
                    <option key={'opt-' + j} value={opt.value}>{opt.label}</option>
                )}
            </select>
        </div>
    );

}

export default Select;