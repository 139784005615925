// Core react and plugins
import React from 'react';


import Menu from '../../components/questionnaire/menu';


const MenuContainer = (props) => {



    return (
        <Menu 
            sections={props.sectionMap}
            { ...props }
        />
    );
}

export default MenuContainer;