import React, { Fragment } from 'react';
import Button from '../common/forms/button';
import { Link, Navigate } from 'react-router-dom';
import Checkbox from '../common/forms/checkbox';
import Error from '../common/forms/error';

function BatchCreate(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    let emailLinkPopup = null

    if(props.emailLink && props.lightBoxOpen){
        emailLinkPopup = <div className="cover">
            <div className="hover-box">
                <Link to='/admin/manage/users' className="close-lnk">Close</Link>
                <h2>Invite Link</h2>
                <div className="flash-msg grn">
                    The user has been created successfully.
                </div>
                <p>You can send the link below to the new user so they can set up a password for their account.</p>
                <p className="lnk-wrap">{props.emailLink}</p>
            </div>
        </div>
    }

    return (
        <Fragment>
            {emailLinkPopup}
            <section className='width-9 centred'>

                <h1>Create new users from a CSV</h1>
                <p><em>N.B. The users default to low-level permissions (only able to respond to the self-assessment).</em></p>

                <Error error={props.error}/>

                <div className="file-upload">
                    { props.csv ? (
                        <label>{ props.csv.name }</label>
                    ) : (
                        <label>Upload a CSV file containing the user details to be uploaded</label>
                    )}
                    <input type="file" onChange={ (e) => props.handleFile(e) } multiple={ false } />
                </div>
                
                <Checkbox label='Return the invitation links, so I can email them to the user myself' id='return_link' handleChange={(e) => props.setReturnLinks(e.target.checked)}/>

                <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.createUsers}/>
                <p className="sml"><Link to="/sample.csv" target="_blank">Download a CSV template</Link></p>
            </section>
  
        </Fragment>
    )
}

export default BatchCreate;