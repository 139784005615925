import React from 'react';
import ResultGraphContainer from '../../../containers/reports/admin/result-graph-container';
import Adventurous from './report-text/adventurous';
import Control from './report-text/control';
import Data from './report-text/data';
import Risk from './report-text/risk';
import DownloadContainer from '../../../containers/reports/download-container';
import moment from 'moment';

function Report(props){
    
    if(props.isLoading){
        return null
    }

    return (
        <div className="content core report">
            <section className="width-12 head">
                <h3>{moment(props.reportDetail.submissionDate).format("DD/MM/YYYY")}</h3>
                <h2>{!props.isCurrentUser ? props.user.firstName+" "+props.user.lastName : "Your results"}</h2>
                <h1>NeuroSphere Report</h1>
            </section>

            <section className="width-6 result">
                <div id="resultsChart">
                    <ResultGraphContainer data={props.reportData} />
                </div>

                <DownloadContainer { ...props }/>
            </section>
  
            { props.bands &&
                <section className="width-6 result">
                    <Risk setOpenSxn={props.setOpenSxn} openSxn={props.openSxn} band={props.bands[1]}/>
                    <Control setOpenSxn={props.setOpenSxn} openSxn={props.openSxn} band={props.bands[2]}/>
                    <Data setOpenSxn={props.setOpenSxn} openSxn={props.openSxn} band={props.bands[3]}/>
                    <Adventurous setOpenSxn={props.setOpenSxn} openSxn={props.openSxn} band={props.bands[4]}/>
                </section>
            }
        </div>
    )
}

export default Report