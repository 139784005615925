import React, { useState } from 'react';
import Download from '../../components/report/download';

import { useCookies } from 'react-cookie';
const  DownloadContainer = (props) =>  {

    const [authCookie] = useCookies(['jdm']);
    const [rdr, setRdr] = useState(false);

    const checkForAuthCookie = () => {
        
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            return false
        } else {
            return true
        }
    }


        return (
            <Download  checkForAuthCookie={checkForAuthCookie} setRdr={setRdr} rdr={rdr} {...props} />
        );

}
export default DownloadContainer;