// Checks a user's auth status and forwards them on accordingly

// Core react and plugins
import React, { useState } from 'react';

// View components
import Routing from './../components/routing';

const RoutingContainer = (props) => {

    // State management
    const [rdr] = useState(false);

    return (
        <Routing
            rdr={ rdr }
        />
    );
}

export default RoutingContainer;
