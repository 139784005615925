import React from 'react';

function Adventurous(props){

    let title = 'Risk'
    let para1 = ''
    // let para2 = ''
    // let para3 =''
    let colour = "ylw";

    let band = props.band;

    switch(band){
        case 1:
            title = 'HIGH ADVENTUROUS'
            para1 = 'Your self-assessment results suggest that you are highly adventurous. You relish the enjoyment of new methods, bright ideas and brand-new experiences.'
            // para1 = 'Your self-assessment results suggest that you are highly adventurous. You relish the enjoyment of new methods, bright ideas and brand-new experiences. You tend to consider the daring undertaking first rather than explore the potential risks. You enjoy the feelings created by being bold, daring, enterprising and intrepid.  '
            // para2 = 'It is likely that you may jump before you think, accepting the consequences, and have learned to move easily out of your comfort zone which has enabled a decent relationship with failure. It is rare that you will say no to any new adventure.'
            // para3 = 'People who are highly adventurous are often seen as early pioneers and draw great strength from the rush of a new activity or experience, gaining new skills and knowledge in the process. You are highly likely to seek strong reward in the pursuit of your new adventures.'
            colour = "gr";
            break;
        case 2:
            title = 'QUITE HIGH ADVENTUROUS'
            para1 = 'Your self-assessment results suggest that you are quite highly adventurous, tending to think first of the benefits to be gained rather than dwell on what may be at risk or lost.'
            // para1 = 'Your self-assessment results suggest that you are quite highly adventurous, tending to think first of the benefits to be gained rather than dwell on what may be at risk or lost. Having said that you will not take on any new adventure without a cursory thought of what is at stake. '
            // para2 = 'It is likely that you have expanded your comfort zone and know what it takes to push yourself further and have a decent tolerance for uncertainty. You will take on most new activities, though will apply a calculated assessment before you do.'
            // para3 = 'People who are quite highly adventurous are often seen as courageous, appreciating that nothing ventured, then nothing gained. You are highly likely to seek reward in the pursuit of your new adventures.'
            colour = "gr";
            break;
        case 3:
            title = 'BALANCED ADVENTUROUS AND CAUTIOUS'
            para1 = 'Your self-assessment results suggest that you strike a perfect balance between being adventurous and being cautious.'
            // para1 = 'Your self-assessment results suggest that you strike a perfect balance between being adventurous and being cautious. You can evaluate the pros versus the cons to form judgement and make decisions which prevents you putting yourself in a position of high risk though by the same token you appreciate the lucrative benefits of a new experience.'
            // para2 = 'It may be that at times you hold yourself back to ensure your thinking has evaluated the fine relationship between risk and reward. Be sure though not to miss the boat!'
            // para3 = 'People who have a propensity for balanced adventurous and cautious are often seen as wise counsel when it comes to group collaboration and joint decision making, making great team players.'
            break;
        case 4:
            title = 'QUITE HIGH CAUTIOUS'
            para1 = 'Your self-assessment results suggest that you are quite cautious in your general approach to things. You require a decent level of certainty attached to any new activity.'
            // para1 = 'Your self-assessment results suggest that you are quite cautious in your general approach to things. You require a decent level of certainty attached to any new activity. You also look towards a reasonable level of safety and security to support new ideas. Though having said that, you throw yourself all-in when you get a sense that it’s right.'
            // para2 = 'It is likely that you may hesitate slightly and allow things to develop with clarity before you embark on a new project or put yourself forward for a new role.'
            // para3 = 'People who have a propensity to be quite cautious tend to be mildly sceptical about the probability of an outcome. They may also take a while to put their total trust into something or someone. People must earn your respect!'
            colour = "gr";
            break;
        case 5:
            title = 'HIGH CAUTIOUS'
            para1 = 'Your self-assessment results suggest that you are highly cautious. You require a high level of certainty attached to any new activity.'
            // para1 = 'Your self-assessment results suggest that you are highly cautious. You require a high level of certainty attached to any new activity. You also look towards safety and security to support new ideas. You are likely to enjoy the status quo, so the effort of being adventurous must also reflect the levels of reward.'
            // para2 = 'It is likely that you may wait for things to develop and become much clearer before you embark on a new project or put yourself forward for a new role. You may also search for many strands of proof before you even contemplate relinquishing caution. '
            // para3 = 'People who have a propensity to be overly cautious tend to be sceptical about the probability of an outcome. Adventure is often seen as misadventure. They also take considerable time to put their total trust into something or someone. People must earn your respect! '
            colour = "gr";
            break;
        default:
            title = 'BALANCED ADVENTUROUS AND CAUTIOUS'
            para1 = 'Your self-assessment results suggest that you strike a perfect balance between being adventurous and being cautious.'
            // para1 = 'Your self-assessment results suggest that you strike a perfect balance between being adventurous and being cautious. You can evaluate the pros versus the cons to form judgement and make decisions which prevents you putting yourself in a position of high risk though by the same token you appreciate the lucrative benefits of a new experience.'
            // para2 = 'It may be that at times you hold yourself back to ensure your thinking has evaluated the fine relationship between risk and reward. Be sure though not to miss the boat!'
            // para3 = 'People who have a propensity for balanced adventurous and cautious are often seen as wise counsel when it comes to group collaboration and joint decision making, making great team players.'
            break;
    }

    return (
        <div className={"narrative adventurous " + colour}>
            <div className="wording">
                <h5>{title}</h5>
                <p>{para1}</p>
            </div>
        </div>
    )
}

export default Adventurous