import React from 'react';

function Data(props){

    let title = 'Data'
    let para1 = ''
    // let para2 = ''
    // let para3 =''
    let colour = "ylw";

    let band = props.band;

    switch(band){
        case 5:
            title = 'HIGH DATA'
            para1 = 'Your self-assessment results suggest that you use huge amounts of data as your primary source of reference when forming judgement. You clearly look for all the tangible evidence and fact-based material to support your considerations when reaching conclusions or making decisions. '
            // para2 = 'It is likely that you place a high value on accuracy and are therefore naturally diligent when it comes to judgement and making recommendations. You are highly likely to be technically minded and naturally enjoy solving complex problems.'
            // para3 = 'People who have a propensity for wanting high data are sometimes described as perfectionists and often like to take their time when reaching decisions to ensure situational accuracy. Be mindful though to know when you have collated enough data for the occasion and act.'
            colour = "gr";
            break;
        case 4:
            title = 'QUITE HIGH DATA'
            para1 = 'Your self-assessment results suggest that you use reasonable amounts of data as your reliable source of reference when forming judgement. You clearly respect the value of evidence and fact-based material to support your considerations when reaching conclusions or making decisions. '
            // para2 = 'It is likely that you appreciate being accurate, applying a level of due diligence but are prepared to back yourself if you don&rsquo;t always have 100% of all the data. You are highly likely to be technically minded and naturally enjoy solving problems. '
            // para3 = 'People who have a propensity for wanting quite high data often begin with the majority of supporting evidence and will then provide a brief sanity check through their own intuition to corroborate what the main facts are telling them.'
            colour = "gr";
            break;
        case 3:
            title = 'BALANCED DATA AND INTUITION'
            para1 = 'Your self-assessment results suggest that you are happy striking a balance of gathering just enough data and then checking in with what your intuition is telling you.'
            // para1 = 'Your self-assessment results suggest that you are happy striking a balance of gathering just enough data and then checking in with what your intuition is telling you. Having a combination of factual evidence and gut feel provides you with the confidence and confirmation you need to form sound judgement. '
            // para2 = 'It is likely you can make reasonably accurate decisions without all the data providing you have some prior reference or experience to back it up. You are highly likely to be able to make everyday decisions at speed, although you may slow down where new activities are yet to be experienced.'
            // para3 = 'People who have a propensity for balanced data and intuition are often seen as the voice of reason when it comes to group collaboration and joint decision making. Sometimes referred to as the glue within the organisation.'
            break;
        case 2:
            title = 'QUITE HIGH INTUITION'
            para1 = 'Your self-assessment results indicate that you are of a quite high intuition persuasion. This suggests you are sensitive to what others may be feeling and receptive to others’ emotions. Although you will more naturally rely on your intuition for general judgement you will also appreciate some proof as confirmation for your decision making.'
            // para2 = 'It is likely that while you may act mainly on instinctive feeling you will also look for some supporting evidence to help apply a modicum of conscious reasoning, ensuring reasonable levels of accuracy are attained..'
            // para3 = 'People who have a propensity for quite high intuition recognise their gut feel, experience and recognition to form judgement and make decisions. They work particularly well with common practices or within familiar situations. '
            colour = "gr";
            break;
        case 1:
            title = 'HIGH INTUITION'
            para1 = 'Your self-assessment results indicate that you are of a high intuition persuasion. You are clearly highly sensitive to what others may be feeling and very receptive to other people’s energies. It is highly likely that you rely on your intuition for general judgement and can nearly always make instant decisions regardless of the situation. '
            // para2 = 'It is likely that you act more on instinctive feeling rather than conscious reasoning, appreciating instinctively when something feels right or wrong. Your high intuition factor suggests your responses are reached with little apparent effort, and typically without conscious awareness.'
            // para3 = 'People who have a propensity for high intuition tend to form judgement effortlessly and can make decisions with speed. They usually rely on the outcomes of their previous decisions to form the next series of intuitive decisions.'
            colour = "gr";
            break;
        default: 
            title = 'BALANCED DATA AND INTUITION'
            para1 = 'Your self-assessment results suggest that you are happy striking a balance of gathering just enough data and then checking in with what your intuition is telling you.'
            // para1 = 'Your self-assessment results suggest that you are happy striking a balance of gathering just enough data and then checking in with what your intuition is telling you. Having a combination of factual evidence and gut feel provides you with the confidence and confirmation you need to form sound judgement. '
            // para2 = 'It is likely you can make reasonably accurate decisions without all the data providing you have some prior reference or experience to back it up. You are highly likely to be able to make everyday decisions at speed, although you may slow down where new activities are yet to be experienced.'
            // para3 = 'People who have a propensity for balanced data and intuition are often seen as the voice of reason when it comes to group collaboration and joint decision making. Sometimes referred to as the glue within the organisation.'
            break;
    }

    return (
        <div className={"narrative data " + colour}>
            <div className="wording">
                <h5>{title}</h5>
                <p>{para1}</p>
            </div>
        </div>
    )
}

export default Data