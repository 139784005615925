import React, { Fragment } from 'react';
import { Link, Navigate} from 'react-router-dom';
import Button from '../../common/forms/button';
import Error from '../../common/forms/error';
import TextInput from '../../common/forms/text-input';

function ManagerList(props){


    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <Fragment>
            <section className="width-9 centred">
                <h1>Managers</h1>
                <Error error={props.error} />
                <table>
                    <thead>
                        <tr>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        { typeof props.managers != "undefined" && props.managers.map( (manager,i) => {

                            let editMode = false;

                            if(typeof props.editManagerMap[i] != 'undefined'){
                                editMode = true;
                            }
                            // if
                            return (
                                <tr key={'tr-'+i}>
                                    <td>{
                                        !editMode ? manager.FirstName : <TextInput id={'first_name_'+i} value={manager.FirstName} handleChange={(e) => props.handleEditUpdate('FirstName',i,e)}/>
                                    }
                                    
                                    </td>
                                    <td>
                                        {
                                            !editMode ? manager.LastName : <TextInput id={'last_name_'+i} value={manager.LastName} handleChange={(e) => props.handleEditUpdate('LastName',i,e)}/>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !editMode ? <Link to='#' onClick={(e) => props.toggleEdit(e,i)} className="action-btn">Edit</Link>: <Button btnTxt={typeof props.savingRequest[i] !== 'undefined' ? 'Saving...' : 'Save'} handleClick={(e) => props.saveManager(i,e)}/>
                                        }
                                        <Link to='#' onClick={(e) => props.deleteManager(manager.ID,e)} className='action-btn red'>Delete</Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </section>
  
        </Fragment>
    )
}

export default ManagerList