// Core react and plugins
import React, {  useState, useEffect } from 'react';
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

import Report from '../../../components/report/admin/report';
import { useCookies } from 'react-cookie';
// // State management
import { useParams,useLocation } from 'react-router-dom';
import useFlash from '../../../store/flash';

const ReportContainer = (props) => {
    let encSecret = '^43w7X6hNq7N4&sn9b';
    const [rdr, setRdr] = useState(false); // eslint-disable-line
    const [openSxn, setOpenSxn] = useState(false);
    const [error, setError] = useState(false); // eslint-disable-line
    const { pathname } = useLocation();
    const [authCookie] = useCookies(['jdm']);
    const [reportData, setReportData] = useState([])
    const [reportDetail, setReportDetail] = useState([])
    const [bands, setBands] = useState(false)
    const [isLoading, toggleIsLoading] = useState(true)
    const [user, setUser] = useState(false)
    const [isCurrentUser, setIsCurrentUser] = useState(false)
    const urlPath = useParams();
    const [flashState, flashActions] = useFlash(); // eslint-disable-line

    const loadReport = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url; 
        // console.log(urlPath)
        if(urlPath.reportSlug){
            url = process.env.REACT_APP_API_BASE + '/report/get/'+urlPath.reportSlug
        } else {
            
            url =process.env.REACT_APP_API_BASE + '/report/mine'
        }
        
        fetch(url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if(typeof data.detail.id !== 'undefined'){
                setReportData(data.detail.scores);
                processBands(data.detail.scores);
                setReportDetail(data.detail);
            } 
            
            loadUsr(data.detail.userId)
            toggleIsLoading(false)
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Make sure the bands are tracked properly for the wording
    // We cannot rely on the array being in a particular order
    const processBands = (scores) => {

        // Firstly, set up the bands object 
        var bands = {};

        // Loop through teh scores and add them into the bands 
        for (var i in scores) {
            bands[scores[i].areaId] = scores[i].band;
        }

        setBands(bands);
    }

    const loadUsr = (userId) => {

        toggleIsLoading(true);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.jdm == "undefined" || authCookie.jdm === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }

        // Get the user details from the server
        var reformData = CryptoJS.AES.decrypt(authCookie.jdm, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8)); 
        
        fetch(process.env.REACT_APP_API_BASE + '/users/'+ userId , {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            let user = {
                phone:data.MobileNo,
                email:data.Email,
                firstName:data.FirstName,
                lastName:data.LastName
            }

            setUser(user)

            if (user.email === reformData.email) {
                setIsCurrentUser(true);
            } else {
                setIsCurrentUser(false);
            }

            toggleIsLoading(false);

        })
        .catch(function (error) {
            setError({ msg: "Sorry but but there was a problem loading data", style: "red" });
        });        
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Report :: Judgement and Decision Making :: NeuroSphere';
        loadReport()
    },[]);
    /* eslint-enable */

    return (
        <Report
            openSxn={openSxn}
            setOpenSxn={setOpenSxn}
            reportData={reportData}
            isLoading={isLoading}
            user={user}
            bands={ bands }
            reportDetail={ reportDetail }
            isCurrentUser={ isCurrentUser }
        />
    );
}

export default ReportContainer;